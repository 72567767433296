import React from 'react';
import { SvgIcon } from '@material-ui/core';

const IsTalkingIcon = ({ ...props }) => (
  <SvgIcon fontSize="inherit" {...props}>
    <title>Group 5 Copy</title>
    <g
      id="Desktop-Layouts"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group-5-Copy" fill="#FFFFFF">
        <rect id="Rectangle" x="0" y="3" width="1" height="15" rx="0.5" />
        <rect id="Rectangle" x="3" y="6" width="1" height="9" rx="0.5" />
        <rect id="Rectangle" x="6" y="4" width="1" height="13" rx="0.5" />
        <rect id="Rectangle" x="9" y="0" width="1" height="20" rx="0.5" />
        <rect id="Rectangle" x="12" y="4" width="1" height="13" rx="0.5" />
      </g>
    </g>
  </SvgIcon>
);

export { IsTalkingIcon };
