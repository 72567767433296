import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const RemoveIconAudi = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" {...props}>
    <g id="system-remove-small">
      <path
        id="Shape"
        d="M7.3,0H0"
        transform="translate(8.348 11.924)"
        fill="none"
        stroke="#ffffff"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <circle
        id="Oval"
        cx="11.478"
        cy="11.478"
        r="11.478"
        transform="translate(0.522 0.522)"
        fill="none"
        stroke="#ffffff"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </g>
    <rect
      id="Rechteck_75"
      data-name="Rechteck 75"
      width="24"
      height="24"
      fill="none"
    />
  </SvgIcon>
);

export { RemoveIconAudi };
