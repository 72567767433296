import { MultiLanguageString } from './utils';

type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<
  infer ElementType
>
  ? ElementType
  : never;

export const allGroups = ['Dealer', 'Importer', 'Both'] as const;
export type Groups = ElementType<typeof allGroups>;
type Overall = 'm' | number;
export type Context =
  | 'overall'
  | 'session'
  | 'liveSession'
  | 'lot'
  | `overall-${Overall}`;
export const allSurveyTypes = ['rating', 'text', 'headline', 'mc'] as const;
export type SurveyType = ElementType<typeof allSurveyTypes>;
export const defaultSurveyTypes: SurveyType[] = ['rating', 'text', 'headline'];

export interface BaseSurveyTemplateItem {
  surveyTemplateId: number;
  surveyGroup: Groups;
  surveyText: Json;
  /** Text shown after survey is done, sometimes BBO refers to it as "answer" */
  additionalText: Json;
  surveyType: SurveyType;
  reportingTag?: string;
}

export interface GetSurveyTemplateItem extends BaseSurveyTemplateItem {
  surveyTemplateItemId: number;
  indentParent: number;
  position: number;
}

export interface SendUserSurveyItem {
  surveyTemplateItemId: number;
  surveyValue: string;
}

export interface SendUserSurvey {
  surveyTemplateId: number;
  originId: number | null;
  surveyItems: SendUserSurveyItem[];
}

export type Indention = 0 | 1 | 2;

export interface GetUserSurveyItem extends SendUserSurveyItem {}
export interface GetUserSurvey extends SendUserSurvey {
  surveyItems: GetUserSurveyItem[];
  /** Unix string */
  createdAt: string;
  username: string;
}
export interface CurrentSurveyValues extends GetSurveyTemplateItem {
  currentVal: string | undefined;
}

/** Needs to be parsed by parseLanguageJson or stringified by stringifyLanguageJson */
export type Json = string;

export interface PostSurveyTemplate {
  surveyContext: Context;
  title: Json;
  startAt: number;
  endAt: number;
  surveyCode?: string;
  channelId?: number;
  thumbnail?: string | null;
  reportingTag?: string;
}

export interface GetSurveyTemplate extends PostSurveyTemplate {
  surveyTemplateId: number;
  templateItems: GetSurveyTemplateItem[];
  market?: string | null;
  requiresSurveyCode: boolean;
}

export interface GetUserSurvey extends SendUserSurvey {
  contextTitle: string;
  surveyItems: GetUserSurveyItem[];
}

export interface PostSurveyTemplateItem extends BaseSurveyTemplateItem {
  indentParent: number | undefined;
  position: number | undefined;
}

export type ParsedMcSurveyText = {
  text: MultiLanguageString[];
  answers: MultiLanguageString[][];
};

export type Endpoints = {
  postTemplate: string;
  getTemplates: string;
  postSurvey: string;
  getSurvey: string;
};
