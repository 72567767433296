import React from 'react';
import { NotImplemented } from '../../utils/NotImplemented';
import { useIsTheme } from '../../theming';
import { RemoveIconNeutral } from './RemoveIconNeutral';
import { RemoveIconAudi } from './RemoveIconAudi';
import { SvgIconProps } from '@material-ui/core';

const RemoveIcon = (props: SvgIconProps) => {
  const theme = useIsTheme();
  if (theme.audi) {
    return <RemoveIconAudi {...props} />;
  } else if (theme.neutral) {
    return <RemoveIconNeutral />;
  } else return <NotImplemented />;
};

export { RemoveIcon };
