import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const GroupPeopleIconAudi = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" {...props} viewBox="0 0 48 48">
    <rect
      id="Rechteck_5"
      data-name="Rechteck 5"
      width="24"
      height="24"
      fill="none"
    />
    <g id="groups-large" transform="translate(4 6)">
      <path
        id="Shape"
        d="M6.1,15H19.5V3a3.01,3.01,0,0,0-3-3H0"
        transform="translate(21 16.5)"
        fill="none"
        stroke="#ffffff"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <rect
        id="Rectangle-path"
        width="12"
        height="12"
        rx="3"
        transform="translate(22.5 0.5)"
        fill="none"
        stroke="#ffffff"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <path
        id="Shape-2"
        data-name="Shape"
        d="M0,15V3A3.008,3.008,0,0,1,3,0H21a3.008,3.008,0,0,1,3,3V15Z"
        transform="translate(0.5 19.5)"
        fill="none"
        stroke="#ffffff"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <rect
        id="Rectangle-path-2"
        data-name="Rectangle-path"
        width="12"
        height="12"
        rx="3"
        transform="translate(6.5 3.5)"
        fill="none"
        stroke="#ffffff"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </g>
  </SvgIcon>
);

export { GroupPeopleIconAudi };
