import React from 'react';
import { SvgIcon } from '@material-ui/core';

const ViewIconAudi = () => (
  <SvgIcon fontSize="inherit" viewBox="0 0 24 24">
    <path
      d="M22.5,12 C22.5,12 18.1,18.5 12,18.5 C5.9,18.5 1.5,12 1.5,12 C1.5,12 5.9,5.5 12,5.5 C18.1,5.5 22.5,12 22.5,12 Z M12,15.5 C13.9329966,15.5 15.5,13.9329966 15.5,12 C15.5,10.0670034 13.9329966,8.5 12,8.5 C10.0670034,8.5 8.5,10.0670034 8.5,12 C8.5,13.9329966 10.0670034,15.5 12,15.5 Z"
      stroke="currentColor"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    />
  </SvgIcon>
);

export { ViewIconAudi };
