import React from 'react';
import { NotImplemented } from '../../utils/NotImplemented';
import { useIsTheme } from '../../theming';
import { ExitFullScreenNeutral } from './ExitFullScreenNeutral';
import { ExitFullScreenAudi } from './ExitFullScreenAudi';

const ExitFullscreenIcon = () => {
  const theme = useIsTheme();
  if (theme.audi) {
    return <ExitFullScreenAudi />;
  } else if (theme.neutral) {
    return <ExitFullScreenNeutral />;
  } else return <NotImplemented />;
};
/** @deprecated Use ExitFullscreenIconUnused instead */
export const ExitFullScreenWrapper = ExitFullscreenIcon;
export { ExitFullscreenIcon };
