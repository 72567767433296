import React from 'react';
import { SvgIcon } from '@material-ui/core';

const NotificationIconAudi = () => {
  return (
    <SvgIcon fontSize="inherit">
      <path
        id="Pfad_104"
        data-name="Pfad 104"
        d="M18.07,15.729c-.736-1.019-1.4-2.067-1.4-2.067V9.5c0-3.2-.829-8.982-7.164-9C3.175.519,2.346,6.3,2.346,9.5v4.162s-.66,1.048-1.4,2.067-.736,2.894,1.3,2.894H16.768C18.806,18.623,18.806,16.748,18.07,15.729Z"
        transform="translate(2.491 0.728)"
        fill="none"
        stroke="#161615"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <path
        id="Pfad_105"
        data-name="Pfad 105"
        d="M19.072,55.763a2.713,2.713,0,0,0,2.474,2.1H21.6a2.712,2.712,0,0,0,2.474-2.1"
        transform="translate(-9.546 -35.087)"
        fill="none"
        stroke="#161615"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <rect
        id="Rechteck_36"
        data-name="Rechteck 36"
        width="24"
        height="24"
        fill="none"
      />
    </SvgIcon>
  );
};

export { NotificationIconAudi };
