import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { TableBody, TableRow } from '@material-ui/core';
import React from 'react';
/**
 * Drag and Drop Components for Tables
 */
const DraggableComponent = (id: string, index: number) => (props: any) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided: any, snapshot: any) => (
        <TableRow
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
          {...props}
        >
          {props.children}
        </TableRow>
      )}
    </Draggable>
  );
};

const DroppableComponent = (
  onDragEnd: (result: any, provided: any) => void
) => (props: any) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={'1'} direction="vertical">
        {(provided: any) => {
          return (
            <TableBody
              ref={provided.innerRef}
              {...provided.droppableProps}
              {...props}
            >
              {props.children}
              {provided.placeholder}
            </TableBody>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};

const handleDrop = (result: any, arr: Array<any>): Array<any> | null => {
  // dropped outside the list
  if (!result.destination) return null;
  const src = result.source.index;
  const dest = result.destination.index;
  if (src === dest) return null;

  const newArr = arr.slice();
  newArr.splice(src, 1);
  newArr.splice(dest, 0, arr[src]);
  return newArr;
};

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: 'rgb(235,235,235)',
  }),
});

export { DraggableComponent, DroppableComponent, handleDrop };
