import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { Form, FormikFormProps, useFormikContext } from 'formik';
import React, { MouseEventHandler, ReactElement, useMemo } from 'react';
import { useEffect, useState } from 'react';
import { LanguageIcon } from '../icons';

/** The Type a Formik Value in the end could have. e.g. string, number, array etc. */
type ElementValue = unknown;
type Language = string;
type LocalizedInitialValue<LocalizedField extends string> = {
  [key in LocalizedField]: LangArray;
};

type InitialValues<LocalizedField extends string> = {
  [key in LocalizedField | string]: ElementValue | LangArray;
};

type P<LocalizedField extends string> = FormikFormProps & {
  /** Labels of the used languages */
  languages: Language[];
  /** Fires when the form language is changed */
  onLanguageChange?: (index: number) => void;
  /** Name of the fields to localize (name property of fields). To localize subcomponents add their name too (react class name). */
  localizeInputs: LocalizedField[] | 'all';
  /**
   * Initial values. Keys are the field names, values are either localized or unlocalized
   *  type unknown only allowed for non localized inputs.
   * Should not be the same pointer as to the formik initialValues. Make a deep copy if needed or a new object.
   */
  initialValues: InitialValues<LocalizedField>;
  autoParse: LocalizedField[] | 'all';
};
type LangArray = { lang: string; value: ElementValue }[];

type ElementValueObject<LocalizedField extends string> = {
  [key in `${LocalizedField}?lang:${number}` | LocalizedField]: ElementValue;
};

type FormikElementMatch<LocalizedField extends string> = {
  elementValueObject: ElementValueObject<LocalizedField>;
  initialValueObject: LocalizedInitialValue<LocalizedField>;
  elementName: LocalizedField;
};

/**
 * Formik component that automatically makes its child inputs localized.
 * Known bug: Conditional rendering of subcomponents causes the form to crash.
 * Workaround is to use display: 'none' instead.
 * */

const LocalizedForm = <LocalizedField extends string>({
  languages,
  onLanguageChange,
  localizeInputs,
  initialValues,
  autoParse,
  ...rest
}: P<LocalizedField>): JSX.Element => {
  const [selectedLanguage, setSelectedLanguage] = useState<number>(0);
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

  const initial = useMemo(() => {
    const newInitialValues: any = {};
    Object.entries(initialValues).forEach((e) => {
      const [k, value] = e;
      const key = k as LocalizedField;
      let newValue: ElementValue = value;
      if (
        ((localizeInputs === 'all' || localizeInputs.includes(key)) &&
          autoParse === 'all') ||
        autoParse.includes(key)
      ) {
        try {
          if (typeof value === 'string') newValue = JSON.parse(value);
        } catch {}
      }
      newInitialValues[key] = newValue;
    });
    return newInitialValues as InitialValues<LocalizedField>;
  }, [initialValues, localizeInputs, autoParse]);

  const {
    errors,
    touched,
    values,
    setFieldValue,
    isSubmitting,
  } = useFormikContext<{ [key in LocalizedField]: ElementValue }>();

  //clones form content in selected language

  //propagate touch from clone element to original
  useEffect(() => {
    foundElements.forEach((el) => {
      const t = Object.keys(touched).find((k) => {
        return k.includes(`${el.props.name}?`) && touched[k] === true;
      });
      touched[el.props.name] = t !== undefined;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touched]);

  //propagate all touched when submitting
  useEffect(() => {
    if (isSubmitting) {
      foundElements.forEach((el) => {
        touched[el.props.name] = true;
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  //propagate values to the orginal child
  useEffect(() => {
    foundElements.forEach((el) => {
      const split = (el.props.name as string).split('.');
      let elementValueObject = values as ElementValueObject<LocalizedField>;
      split.forEach((s, i) => {
        if (i >= split.length - 1) return;
        const s_index = Number(s);
        if (s_index) {
          elementValueObject = elementValueObject[s_index];
        } else {
          elementValueObject = elementValueObject[s];
        }
      });
      const elName = split[split.length - 1] as LocalizedField;

      let langArray = elementValueObject[elName] as LangArray;
      if (!Array.isArray(langArray)) {
        langArray = [];
      }
      languages.forEach((l, i) => {
        const thisLangIndex = langArray.findIndex((e) => e.lang === l);

        const value = elementValueObject[`${elName}?lang:${i}`];
        //only uses the localized values, which should be of correct type

        const element = initial[elName] as LangArray | undefined;
        if (thisLangIndex >= 0) {
          langArray[thisLangIndex] = {
            lang: l,
            value: value !== undefined ? value : element?.[i]?.value,
          };
        } else {
          langArray.push({
            lang: l,
            value: value !== undefined ? value : element?.[i]?.value,
          });
        }
      });

      elementValueObject[elName] = langArray; // Does not trigger rerender as would setFieldValue do.
    });
    Object.keys(values) &&
      setFieldValue(Object.keys(values)[0], values[Object.keys(values)[0]]); // hack to force rerender once of parent formik
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, touched, values, setFieldValue, selectedLanguage]);

  const getContent = (language: number) => {
    return React.Children.map(rest.children, (child) => {
      if (React.isValidElement(child)) {
        return getLocalized(child, language);
      }
      return child;
    });
  };

  const findFormikElement = (
    name: string
  ): FormikElementMatch<LocalizedField> => {
    const nestedFormNames = name.split('.');
    const elName = nestedFormNames[
      nestedFormNames.length - 1
    ] as LocalizedField;
    let elementValueObject = values;
    let initialValueObject:
      | unknown
      | LangArray
      | InitialValues<LocalizedField> = initial;
    nestedFormNames.forEach((nestedFormName, i) => {
      // Skip last entry, because it is the elements true name.
      if (i >= nestedFormNames.length - 1) return;
      const num = Number(nestedFormName);
      // if it is a number it is a clone of the same element in another language
      if (num) {
        elementValueObject = elementValueObject[num];
        initialValueObject = (initialValueObject as LangArray)[num];
      } else {
        elementValueObject = elementValueObject[nestedFormName];
        initialValueObject = (initialValueObject as InitialValues<LocalizedField>)[
          nestedFormName
        ];
      }
    });
    return {
      elementValueObject: elementValueObject as ElementValueObject<LocalizedField>,
      initialValueObject: initialValueObject as LocalizedInitialValue<LocalizedField>,
      elementName: elName,
    };
  };

  const manipulateFormikValues = (
    {
      elementName,
      elementValueObject,
      initialValueObject,
    }: FormikElementMatch<LocalizedField>,
    language: number
  ) => {
    if (!(`${elementName}?lang:${language}` in elementValueObject)) {
      try {
        elementValueObject[`${elementName}?lang:${language}`] =
          (initialValueObject as LocalizedInitialValue<LocalizedField>)[
            elementName
          ].find((el) => el.lang === languages[language])?.value || '';
      } catch {
        elementValueObject[`${elementName}?lang:${language}`] = '';
      }
    }
  };

  // clones an element in selected language
  const getLocalized = (element: ReactElement, language: number): any => {
    if (
      'name' in element.props &&
      (localizeInputs === 'all' || localizeInputs.includes(element.props.name))
    ) {
      /** The original element props */
      const cloneProperties: {
        [key: string]: unknown;
      } = {};
      cloneProperties['name'] = `${element.props.name}?lang:${language}`;

      if ('label' in element.props) {
        cloneProperties[
          'label'
        ] = `${element.props.label} (${languages[language]})`;
      }

      manipulateFormikValues(findFormikElement(element.props.name), language);

      const el = React.cloneElement(element, cloneProperties);
      if (
        foundElements.findIndex((x) => x.props.name === element.props.name) ===
        -1
      )
        foundElements.push(element);
      return el;
    } else if (
      element.props.children &&
      typeof element.props.children == 'function'
    ) {
      //localize the content of a react component that has a functional children property (such as FieldArray)
      const localizedFunction = (args: any) =>
        getLocalized(element.props.children(args), language);
      return React.cloneElement(element, element.props, localizedFunction);
    } else if (
      element.props.children &&
      React.Children.count(element.props.children) > 0 &&
      element.type !== 'function'
    ) {
      return React.cloneElement(element, {
        children: React.Children.map(element.props.children, (child) => {
          if (React.isValidElement(child)) {
            return getLocalized(child, language);
          }
          return child;
        }),
      });
    } else {
      //localize the content of a react component that contains components itself
      const key = element.key as LocalizedField;
      if (localizeInputs.includes(key)) {
        const t = element.type;
        if (typeof t === 'function') {
          const f: Function = t;
          const children = f(element.props);
          if (children && children.props.children) {
            return getLocalized(children, language);
          }
        }
      }
    }
    return element;
  };

  const handleClose = () => setAnchor(null);
  const foundElements: ReactElement[] = [];
  const handleOpen: MouseEventHandler<HTMLButtonElement> = (event) =>
    setAnchor(event.currentTarget);

  return (
    <div>
      <>
        <div style={{ float: 'right' }}>
          <IconButton
            onClick={handleOpen}
            style={{ marginRight: 0, marginLeft: 'auto' }}
          >
            <LanguageIcon />
          </IconButton>
        </div>

        <Menu
          id="menu-login"
          anchorEl={anchor}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={!!anchor}
          onClose={handleClose}
        >
          {languages!.map((l, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                setSelectedLanguage(i);
                handleClose();
                onLanguageChange?.(i);
              }}
            >
              {l}
            </MenuItem>
          ))}
        </Menu>
      </>

      <Form {...rest}>{getContent(selectedLanguage)}</Form>
    </div>
  );
};

export { LocalizedForm };
