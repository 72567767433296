import React from 'react';
import { SvgIcon } from '@material-ui/core';

const MuteIconAudi = () => (
  <SvgIcon fontSize="inherit">
    <path
      d="M11.5,22.5 C17.5751322,22.5 22.5,17.5751322 22.5,11.5 C22.5,5.42486775 17.5751322,0.5 11.5,0.5 C5.42486775,0.5 0.5,5.42486775 0.5,11.5 C0.5,17.5751322 5.42486775,22.5 11.5,22.5 Z M5.5,9.5 L8.5,9.5 L8.5,13.5 L5.5,13.5 L5.5,9.5 Z M8.5,13.5 L11.5,15.4 L11.5,7.5 L8.5,9.5 M14.1,10 C14.6,10.8 14.9,12.2 13.2,13.9 M15.9,7.8 C16.9,8.9 19.1,12.2 15.5,15.6 M17.8,2.5 L3.7,19.3 L17.8,2.5 Z"
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export { MuteIconAudi };
