import {
  RadioButton as AudiRadioButton,
  RadioButtonProps,
} from '@audi/audi-ui-react';
import clsx from 'clsx';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import { useIsTheme } from '../theming';

interface RadioBtnProps extends RadioButtonProps {
  inputId: string;
  name: string;
  value: string;
}

const RadioButton: React.FC<RadioBtnProps> = (props) => {
  const { errors, touched } = useFormikContext();
  const error = touched[props.name] ? errors[props.name] : '';
  const theme = useIsTheme();
  return (
    <div className={clsx(theme.neutralDark && 'darkRadioBtn')}>
      <Field
        inputId={props.inputId}
        value={props.value}
        invalid={!!error}
        name={props.name}
        as={AudiRadioButton}
      >
        {props.children}
      </Field>
    </div>
  );
};

export { RadioButton };
