import React from 'react';
import { useIsTheme } from '../../theming';
import { ThumbUpIconOutlineAudi } from './ThumbUpIconOutlineAudi';
import { ThumbUpIconOutlineDark } from './ThumbUpIconOutlineDark';
import { ThumbUpIconOutlineLight } from './ThumbUpIconOutlineLight';
import { NotImplemented } from '../../utils/NotImplemented';

export const ThumbUpIconOutline = () => {
  const theme = useIsTheme();
  if (theme.audi) {
    return <ThumbUpIconOutlineAudi />;
  } else if (theme.neutralDark) {
    return <ThumbUpIconOutlineDark />;
  } else if (theme.neutralLight) {
    return <ThumbUpIconOutlineLight />;
  } else {
    return <NotImplemented />;
  }
};

/** @deprecated Use ThumbUpIconOutline instead */
export const UpOutlineWrapper = ThumbUpIconOutline;
