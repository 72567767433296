import React from 'react';
import { SvgIcon } from '@material-ui/core';

const ExitFullScreenAudi = () => (
  <SvgIcon fontSize="inherit">
    <path
      d="M20,10.5 L13.5081,10.5 L13.5081,4 M21.5,2.5 L13.5,10.5 M2.5,21.5 L10.5,13.5 M10.5,20 L10.5,13.5 L4.0081,13.5"
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export { ExitFullScreenAudi };
