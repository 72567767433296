import React from 'react';
import { makeStyles, SvgIcon } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  svgWrapper: {
    fontSize: '30px',
  },
}));

const CancelIconNeutral = () => {
  const cls = useStyles();
  return (
    <SvgIcon className={cls.svgWrapper}>
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
    </SvgIcon>
  );
};

export { CancelIconNeutral };
