import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FileTypes, FormProps } from './FormProps';
import { IconButton } from '@material-ui/core';
import { Text } from '../base/Text/Text';
import { SmallUploadIcon } from '../icons/SmallUploadIcon/SmallUploadIcon';

const useStyles = makeStyles({
  error: {
    color: '#BB0A30',
  },
});

interface UploadButtonProps extends FormProps {
  handleFile: (name: string, file: File) => void;
  type: FileTypes;
  noText?: boolean;
  imgDimensions?: string;
}

const UploadButton = (props: UploadButtonProps) => {
  const hiddenFileInput = React.useRef(null);
  const cls = useStyles();

  const handleClick = () => {
    // @ts-ignore
    hiddenFileInput.current.click();
  };

  const handleChange = (event: any) => {
    const fileUploaded = event.target.files[0];
    props.handleFile(props.name, fileUploaded);
  };

  const getAccept = (): string => {
    switch (props.type) {
      case 'image':
        return 'image/png,image/jpeg,image/svg+xml';
      case 'pdf':
        return 'application/pdf';
      case 'mixed':
        return 'image/png,image/jpeg,application/pdf';
      case 'vtt':
        return '.vtt';
      case 'video':
        return 'video/mp4';
      case 'csv':
        return '.csv';
    }
  };

  return (
    <div>
      <div>
        <IconButton onClick={handleClick}>
          <SmallUploadIcon />
        </IconButton>

        <Text as="span" variant="copy1">
          {props.noText ? '' : 'Uploading: '}
          {props.label}{' '}
          {props.imgDimensions && (
            <Text variant="copy2" as="span">
              ({props.imgDimensions})
            </Text>
          )}
        </Text>
      </div>
      <Text as="p" variant="copy2" className={cls.error}>
        {props.error}
      </Text>
      <input
        type="file"
        accept={getAccept()}
        ref={hiddenFileInput}
        onChange={handleChange}
        onClick={(event) => {
          event.currentTarget.value = ''; // Allows handleChange to be called when selecting the same file again
          handleChange(event);
        }}
        style={{ display: 'none' }}
      />
    </div>
  );
};

export { UploadButton, UploadButtonProps };
