import React from 'react';
import { SvgIcon } from '@material-ui/core';

const EnterFullScreenAudi = () => (
  <SvgIcon fontSize="inherit">
    <path
      d="M15,2.5 L21.4919,2.5 L21.4919,9 M13.5,10.5 L21.5,2.5 M10.5,13.5 L2.5,21.5 M2.5,15 L2.5,21.5 L8.9919,21.5"
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export { EnterFullScreenAudi };
