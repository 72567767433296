import React from 'react';
import { makeStyles, SvgIcon } from '@material-ui/core';

const useStyle = makeStyles(() => ({
  icon: {
    margin: '2px 8px 2px -4px',
    'pointer-events': 'all',
    padding: '0',
  },
}));

const LikeIconInactiveDark = () => {
  const cls = useStyle();
  return (
    <SvgIcon
      className={cls.icon}
      style={{ width: 48, height: 48 }}
      viewBox="0 -1 48 48"
    >
      <g
        id="Desktop-Layouts"
        strokeWidth="1"
        fill="none"
        stroke="white"
        fillRule="evenodd"
      >
        <g
          id="LOT_player_icons_new"
          transform="translate(-197.000000, -98.000000)"
        >
          <g id="thumbs-up" transform="translate(197.000000, 98.000000)">
            <circle
              id="Oval-Copy-14"
              fill="#000000"
              cx="24.5"
              cy="22.5"
              r="22.5"
            ></circle>
            <g
              transform="translate(13.000000, 11.000000)"
              fill="#FFFFFF"
              id="Fill-1"
            >
              <path d="M9 21h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.58 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2zM9 9l4.34-4.34L12 10h9v2l-3 7H9V9zM1 9h4v12H1z"></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export { LikeIconInactiveDark };
