import React from 'react';
import { NotImplemented } from '../../utils/NotImplemented';
import { useIsTheme } from '../../theming';
import { PlusIconNeutral } from './PlusIconNeutral';
import { PlusIconAudi } from './PlusIconAudi';
import { SvgIconProps } from '@material-ui/core';

const PlusIcon = (props: SvgIconProps) => {
  const theme = useIsTheme();
  if (theme.audi) {
    return <PlusIconAudi {...props} />;
  } else if (theme.neutral) {
    return <PlusIconNeutral />;
  } else return <NotImplemented />;
};

export { PlusIcon };
