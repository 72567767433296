import React from 'react';

interface OnlineIconProps {
  online: boolean;
}

const OnlineIcon = (props: OnlineIconProps) => (
  <svg width="16px" height="16px">
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Viewer/hand-raised"
        transform="translate(-39.000000, -35.000000)"
        fill={props.online ? '#009900' : '#bb0a30'}
        stroke="#F2F2F2"
        strokeWidth="2"
      >
        <circle id="Oval" cx="47" cy="43" r="7" />
      </g>
    </g>
  </svg>
);

export { OnlineIcon };
