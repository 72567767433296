import React from 'react';
import { SvgIcon } from '@material-ui/core';

const UnMuteIconAudi = () => (
  <SvgIcon fontSize="inherit" viewBox="0 0 24 24">
    <polyline points="100,100 150,25 150,75 200,0" fill="none" stroke="white" />
    <path
      d="M7.5,14.5 L3.5,14.5 L3.5,8.5 L7.5,8.5 L7.5,14.5 Z M7.5,14.5 L11.5,17.4 L11.5,5.6 L7.5,8.5 M17.5,17.7 C24.2,11.2 17.5,5.4 17.5,5.4 M15,15 C16.9,13 16.9,9.9 15,7.9"
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export { UnMuteIconAudi };
