import { NotImplemented } from '../../utils/NotImplemented';
import { useIsTheme } from '../../theming';
import React from 'react';
import { PlayIconNeutral } from '../ControlIcon/PlayIconNeutral';
import { PlayIconAudi } from './PlayIconAudi';

const SessionPlayIcon = () => {
  const theme = useIsTheme();
  if (theme.audi) {
    return <PlayIconAudi />;
  } else if (theme.neutral) {
    return <PlayIconNeutral />;
  } else return <NotImplemented />;
};

export { SessionPlayIcon };
