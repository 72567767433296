import React from 'react';
import axios, { AxiosInstance } from 'axios';
import { allSurveyTypes, Context, Endpoints, SurveyType } from './Interfaces';
import { defaultStrings, SurveyStrings } from './strings';

export type MessageType = 'success' | 'error';
export interface SurveyProviderValue {
  client: AxiosInstance;
  strings: SurveyStrings;
  notify: (msg: string, type: MessageType, persist?: boolean) => void;
  importerLogic: boolean;
  surveyContexts: Context[];
  useTag?: boolean;
  /** Use defaultSurveyTypes if migrating, allSurveyTypes if you want everything or just specify a few */
  surveyTypes?: readonly SurveyType[];
  endpoints?: Endpoints;
  /** Scrolls to top after a survey is made */
  scrollToTop?: boolean;
  maxStars?: number;
  /** Disable unlock codes. Just hides the form field. Nullable fields are always allowed. */
  noUnlockCodes?: boolean;
}

export interface SurveyProviderProps {
  value: SurveyProviderValue;
}

export const SurveyContext = React.createContext<SurveyProviderValue>({
  client: axios,
  strings: defaultStrings,
  notify: () => {},
  importerLogic: false,
  surveyContexts: [],
  useTag: false,
  surveyTypes: allSurveyTypes,
  maxStars: 5,
});

export const SurveyProvider: React.FC<SurveyProviderProps> = (props) => (
  <SurveyContext.Provider value={props.value}>
    {props.children}
  </SurveyContext.Provider>
);
