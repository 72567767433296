import React from 'react';
import { NotImplemented } from '../../utils/NotImplemented';
import { useIsTheme } from '../../theming';
import { DisLikeIconActiveAudi } from './DisLikeIconActiveAudi';
import { DisLikeIconActiveLight } from './DisLikeIconActiveLight';
import { DisLikeIconActiveDark } from './DisLikeIconActiveDark';

const DisLikeIconActive = () => {
  const theme = useIsTheme();
  if (theme.audi) {
    return <DisLikeIconActiveAudi />;
  } else if (theme.neutralLight) {
    return <DisLikeIconActiveLight />;
  } else if (theme.neutralDark) {
    return <DisLikeIconActiveDark />;
  } else return <NotImplemented />;
};
export { DisLikeIconActive };
