import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const LiveIconAudi = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" {...props}>
    <rect
      id="Rechteck_5"
      data-name="Rechteck 5"
      width="24"
      height="24"
      fill="none"
    />
    <g
      id="vehicle-wi-fi-3-large"
      transform="translate(23.878 3.803) rotate(90)"
    >
      <path
        id="Path"
        d="M15.395,2.385A12.49,12.49,0,0,0,7.7,0,12.423,12.423,0,0,0,0,2.385"
        transform="translate(0.5 0.5)"
        fill="none"
        stroke="#ffffff"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <g
        id="Ellipse_1"
        data-name="Ellipse 1"
        transform="translate(5.812 9.493)"
        fill="none"
        stroke="#ffffff"
        strokeWidth="0.5"
      >
        <circle cx="2.385" cy="2.385" r="2.385" stroke="none" />
        <circle cx="2.385" cy="2.385" r="1.885" fill="none" />
      </g>
      <path
        id="Path-2"
        data-name="Path"
        d="M8.062,1.532A5.738,5.738,0,0,0,4.031,0,5.738,5.738,0,0,0,0,1.532"
        transform="translate(12.229 18.099) rotate(180)"
        fill="none"
        stroke="#ffffff"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        id="Path-3"
        data-name="Path"
        d="M0,1.532A5.738,5.738,0,0,1,4.031,0,5.738,5.738,0,0,1,8.062,1.532"
        transform="translate(4.166 5.658)"
        fill="none"
        stroke="#ffffff"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        id="Path-4"
        data-name="Path"
        d="M15.395,2.385A12.49,12.49,0,0,0,7.7,0,12.423,12.423,0,0,0,0,2.385"
        transform="translate(15.895 23.257) rotate(180)"
        fill="none"
        stroke="#ffffff"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
    </g>
  </SvgIcon>
);

export { LiveIconAudi };
