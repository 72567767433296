import React, { useEffect, useState } from 'react';
import { AppBar, Link, Toolbar } from '@material-ui/core';
import {
  makeThemedStyles,
  Text,
  useThemedStyles,
} from '@pp-labs/ui-components';
import { useGetLocalizedValue, useSurveyTemplates } from './utils';
import { GeneralSurvey } from './GeneralSurvey';

const style = makeThemedStyles(() => {
  return {
    link: {
      marginRight: '36px',
      '&:hover': {
        cursor: 'pointer',
      },
      '&:hover, &:active': {
        textDecoration: 'none',
      },
    },
    active: {
      borderBottom: ({ isTheme }) =>
        isTheme.neutralDark ? '2px solid white' : '2px solid black',
      paddingBottom: '5px',
    },
    text: {
      color: '#666666',
    },
    activeText: {
      color: ({ colorScheme }) => colorScheme.text,
    },
  };
});

interface LinkProps {
  text: string;
  index: number;
  change: (newIndex: number) => void;
  currentValue: number;
}

const MyLink = (props: LinkProps) => {
  const cls = useThemedStyles(style);
  const b =
    props.index === props.currentValue ? `${cls.active} ${cls.link}` : cls.link;
  const t = props.index === props.currentValue ? cls.activeText : cls.text;
  return (
    <Link className={b} onClick={() => props.change(props.index)}>
      <Text className={t} as="span" variant="copy1">
        {props.text}
      </Text>
    </Link>
  );
};

const styles = makeThemedStyles(() => {
  return {
    root: {
      position: 'relative',
      top: 0,
      backgroundColor: ({ colorScheme }) => colorScheme.background,
      minWidth: '100%',
      padding: 56,
    },
    padding: {
      backgroundColor: ({ colorScheme }) => colorScheme.background,
      minWidth: '100%',
      padding: 56,
    },
    wrapper: {
      background: ({ isTheme }) => (isTheme.neutralDark ? 'black' : undefined),
    },
  };
});

export interface SurveyLandingProps {
  initialSelectedSurveyId?: number;
}

export const SurveyLanding = (props: SurveyLandingProps) => {
  const cls = useThemedStyles(styles);
  const getLocalized = useGetLocalizedValue();
  const surveys = useSurveyTemplates(true, true);
  const [selectedSurvey, setSelectedSurvey] = useState<number>(0);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    //Select the survey corresponding to props.initialSelectedSurveyId
    if (surveys.length > 0 && !loaded && props.initialSelectedSurveyId) {
      const paramId = props.initialSelectedSurveyId;
      const initialIndex = surveys.findIndex(
        (s) => s.surveyTemplateId === (isNaN(paramId) ? 0 : paramId)
      );
      setSelectedSurvey(initialIndex >= 0 ? initialIndex : 0);
      setLoaded(true);
    }
  }, [surveys]);

  const survey = surveys.find((_t, index) => index === selectedSurvey);
  if (surveys.length === 1) {
    return (
      <div className={cls.padding}>
        {survey && (
          <GeneralSurvey
            id={0}
            context={survey.surveyContext}
            accessor={(t) => t}
            live={false}
          />
        )}
      </div>
    );
  }
  return (
    <>
      <AppBar
        color="inherit"
        elevation={0}
        className={`navbarSep ${cls.wrapper}`}
        position="static"
      >
        <Toolbar>
          <div style={{ paddingLeft: '32px' }}>
            {surveys.map((t, i) => (
              <React.Fragment key={i}>
                <MyLink
                  text={getLocalized(t.title)}
                  index={i}
                  change={setSelectedSurvey}
                  currentValue={selectedSurvey}
                />
              </React.Fragment>
            ))}
          </div>
        </Toolbar>
      </AppBar>

      <div className={cls.root}>
        {survey && (
          <GeneralSurvey
            id={0}
            context={survey.surveyContext}
            accessor={(t) => t}
            live={false}
          />
        )}
      </div>
    </>
  );
};
