import React from 'react';
import { useIsTheme } from '../../theming';
import { PlayIconAudi } from './PlayIconAudi';
import { NotImplemented } from '../../utils/NotImplemented';
import { PlayIconNeutral } from './PlayIconNeutral';
import { SvgIconProps } from '@material-ui/core';

const PlayIcon = (props: SvgIconProps) => {
  const theme = useIsTheme();
  if (theme.audi) {
    return <PlayIconAudi {...props} />;
  } else if (theme.neutral) {
    return <PlayIconNeutral />;
  } else return <NotImplemented />;
};

export { PlayIcon };
