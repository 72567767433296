import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface P {
  image?: boolean;
  video?: boolean;
}

const useStyle = makeStyles({
  image: {
    color: 'white',
    fontSize: '44px',
  },
  video: {
    fontSize: '44px',
    color: 'black',
  },
});
const UploadIcon = (props: P) => {
  const cls = useStyle();
  return (
    <SvgIcon
      fontSize={'inherit'}
      className={props.image ? cls.image : props.video ? cls.video : ''}
    >
      <path
        d="M11.5,22.5 C17.5751322,22.5 22.5,17.5751322 22.5,11.5 C22.5,5.42486775 17.5751322,0.5 11.5,0.5 C5.42486775,0.5 0.5,5.42486775 0.5,11.5 C0.5,17.5751322 5.42486775,22.5 11.5,22.5 Z M11.5,16 L11.5,9.6 M8.3,12.8 L11.5,9.6 L14.7,12.8 M15,7.5 L8,7.5"
        stroke="currentColor"
        fill="none"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export { UploadIcon };
