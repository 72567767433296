import React from 'react';
import { NotImplemented } from '../../utils/NotImplemented';
import { useIsTheme } from '../../theming';
import { MyScheduleIconDark } from './MyScheduleIconDark';
import { MyScheduleIconLight } from './MyScheduleIconLight';
import { MyScheduleIconAudi } from './MyScheduleIconAudi';

const MyScheduleIcon = () => {
  const theme = useIsTheme();

  if (theme.neutralDark) {
    return <MyScheduleIconDark />;
  } else if (theme.neutralLight) {
    return <MyScheduleIconLight />;
  } else if (theme.audi) {
    return <MyScheduleIconAudi />;
  } else return <NotImplemented />;
};

export { MyScheduleIcon };
