import { makeStyles } from '@material-ui/core';
import { FC, useMemo, useState } from 'react';
import {
  EditDialog,
  LabelButton,
  Spacer,
  TextField,
} from '@pp-labs/ui-components';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { GetSurveyTemplate } from '../Interfaces';
import { useSurveyContext } from '../utils';
import React from 'react';

const useStyles = makeStyles({
  form: {
    width: 400,
    maxWidth: '100%',
  },
});

type T = {
  type: 'test';
  verify: { examId: number; hasCode: boolean };
};

type S = {
  type: 'survey';
  verify: GetSurveyTemplate;
};
type P = (S | T) & { close: () => void; bypass?: boolean };

const getValidation = (strings: { required: string }) =>
  Yup.object({
    code: Yup.string().required(strings.required),
  }).required(strings.required);

type F = Yup.InferType<ReturnType<typeof getValidation>>;

export const CodeProtection: FC<P> = (props) => {
  const cls = useStyles();
  const context = useSurveyContext();
  const [unlocked, setUnlocked] = useState<boolean>(false);
  const submit = async (
    values: F,
    setFieldError: (name: 'code', value: string) => void
  ) => {
    const baseUrl =
      props.type === 'survey'
        ? `surveys/template/${props.verify.surveyTemplateId}`
        : `exams/${props.verify.examId}`;
    const isValid = (
      await context.client.get<boolean>(`${baseUrl}/verify?code=${values.code}`)
    ).data;
    if (isValid) {
      setUnlocked(true);
    } else {
      setFieldError('code', context.strings.invalidUnlockCode);
    }
  };

  const validationSchema = useMemo(
    () => getValidation(context.strings),
    [context.strings]
  );
  const isAccessible =
    props.bypass ||
    ('hasCode' in props.verify && !props.verify.hasCode) ||
    unlocked;
  // skip the dialog here
  if (isAccessible && props.type === 'test') return <>{props.children}</>;
  return (
    <EditDialog
      title={isAccessible ? '' : context.strings.enterUnlockCode}
      close={props.close}
      closeCrossOnly={isAccessible}
    >
      {isAccessible ? (
        props.children
      ) : (
        <Formik
          initialValues={{ code: '' }}
          validationSchema={validationSchema}
          onSubmit={(values, { setFieldError }) =>
            submit(values, setFieldError)
          }
        >
          {({ touched, errors }) => {
            const et = {
              code: touched.code ? errors.code : '',
            };
            return (
              <Form className={cls.form}>
                <TextField
                  name="code"
                  label={context.strings.enterUnlockCode || 'Enter unlock code'}
                  error={et.code}
                />
                <Spacer spaceStackStart="m" />
                <LabelButton variant="primary" type="submit">
                  {context.strings.submitUnlockCode || 'Submit unlock code'}
                </LabelButton>
              </Form>
            );
          }}
        </Formik>
      )}
    </EditDialog>
  );
};
