import {
  DownloadButton,
  EditDialog,
  LabelButton,
  LocalizedForm,
  Select,
  TextField,
  UploadButton,
  Text,
} from '@pp-labs/ui-components';
import { Field, Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { DatePicker } from '../DatePicker';
import * as Yup from 'yup';
import { Context, GetSurveyTemplate, PostSurveyTemplate } from '../Interfaces';
import { LayoutItem } from '@pp-labs/ui-components';
import {
  atLeastOneLanguage,
  getMatchingLang,
  isMainSurvey,
  matchingList,
  MultiLanguageString,
  optionalThumb,
  parseLanguageJson,
  requiredThumb,
  stringifyLanguageJson,
  useSurveyContext,
} from '../utils';
import axios from 'axios';
import { Button } from '@material-ui/core';

const getValidation = (strings: { required: string }, edit: boolean) => {
  return Yup.object({
    yearStart: Yup.string().required(strings.required),
    monthStart: Yup.string().required(strings.required),
    dayStart: Yup.string().required(strings.required),
    hourStart: Yup.string().required(strings.required),
    minuteStart: Yup.string().required(strings.required),
    yearEnd: Yup.string().required(strings.required),
    monthEnd: Yup.string().required(strings.required),
    dayEnd: Yup.string().required(strings.required),
    hourEnd: Yup.string().required(strings.required),
    minuteEnd: Yup.string().required(strings.required),
    title: atLeastOneLanguage(),
    code: Yup.string(),
    type: Yup.string().required(strings.required),
    thumbnail: edit ? optionalThumb() : requiredThumb(strings.required),
  }).required(strings.required);
};

type F = Yup.InferType<ReturnType<typeof getValidation>> & {
  thumbnail: File | null;
  tag?: string;
  title?: MultiLanguageString[];
};

interface P {
  prevSurvey?: GetSurveyTemplate;
  context: Context;
  refresh: () => void;
  close: () => void;
  channelId: number | undefined;
  languages: string[];
}

type Response = {
  thumbnail?: {
    url: string;
    contentType: string;
  };
};
export const EditSurvey = (props: P) => {
  const context = useSurveyContext();

  //Tagging
  const [loadedList, setLoadedList] = useState<boolean>(false);
  const [tagList, setTagList] = useState<Array<string>>([]);
  useEffect(() => {
    const getSessionTagList = async () => {
      const data = (await context.client.get(`surveys/reportingTags`))
        .data as Array<string>;
      setTagList(data);
    };
    if (!loadedList && context.useTag) {
      setLoadedList(true);
      getSessionTagList();
    }
  }, []);

  const getMatchingList = (values: F) => matchingList(values.tag, tagList);

  const validation = useMemo(
    () => getValidation(context.strings, !!props.prevSurvey),
    []
  );
  const initialValues: F = useMemo(() => {
    const startDate = props.prevSurvey?.startAt
      ? new Date(Number(props.prevSurvey?.startAt) * 1000)
      : new Date();
    const endDate = props.prevSurvey?.endAt
      ? new Date(Number(props.prevSurvey?.endAt) * 1000)
      : new Date();
    return {
      yearStart: startDate.getFullYear().toString(),
      monthStart: startDate.getMonth().toString(),
      dayStart: startDate.getDate().toString(),
      hourStart: startDate.getHours().toString(),
      minuteStart: (Math.floor(startDate.getMinutes() / 15) * 15).toString(),
      yearEnd: endDate.getFullYear().toString(),
      monthEnd: endDate.getMonth().toString(),
      dayEnd: endDate.getDate().toString(),
      hourEnd: endDate.getHours().toString(),
      minuteEnd: (Math.floor(endDate.getMinutes() / 15) * 15).toString(),
      title: parseLanguageJson(props.prevSurvey?.title),
      code: props.prevSurvey?.surveyCode || '',
      type:
        props.prevSurvey && isMainSurvey(props.prevSurvey) ? 'main' : 'side',
      thumbnail: null,
      tag: props.prevSurvey?.reportingTag || '',
    };
  }, [props.prevSurvey]);

  const submit = async (values: F) => {
    const start = new Date(
      Number(values.yearStart),
      Number(values.monthStart),
      Number(values.dayStart),
      Number(values.hourStart),
      Number(values.minuteStart)
    );
    const end = new Date(
      Number(values.yearEnd),
      Number(values.monthEnd),
      Number(values.dayEnd),
      Number(values.hourEnd),
      Number(values.minuteEnd)
    );
    const surveyContext = values.type === 'main' ? 'overall-m' : props.context;
    const data: PostSurveyTemplate = {
      surveyContext: surveyContext,
      startAt: start.getTime() / 1000,
      endAt: end.getTime() / 1000,
      title: stringifyLanguageJson(values.title),
      surveyCode: values.code,
      channelId: props.channelId,
      thumbnail: values.thumbnail?.name,
      reportingTag:
        values.tag || getMatchingLang(values.title!, props.languages[0]),
    };
    const res = props.prevSurvey
      ? await context.client.put<Response>(
          `${context.endpoints.postTemplate}/${props.prevSurvey.surveyTemplateId}`,
          data
        )
      : await context.client.post<Response>(
          context.endpoints.postTemplate,
          data
        );

    if (values.thumbnail && res.data.thumbnail) {
      await axios.put(res.data.thumbnail.url, values.thumbnail, {
        headers: { 'Content-Type': res.data.thumbnail.contentType },
      });
    }
    await props.refresh();
    props.close();
  };

  return (
    <EditDialog title="Add Survey" close={props.close}>
      <Formik
        validationSchema={validation}
        onSubmit={submit}
        initialValues={initialValues}
      >
        {({ values, errors, touched, setFieldValue }) => {
          const e = errors as unknown as {
            [index in keyof F]: string | undefined;
          };
          const et = {
            yearStart: touched.yearStart ? e.yearStart : '',
            monthStart: touched.monthStart ? e.monthStart : '',
            dayStart: touched.dayStart ? e.dayStart : '',
            hourStart: touched.hourStart ? e.hourStart : '',
            minuteStart: touched.minuteStart ? e.minuteStart : '',
            yearEnd: touched.yearEnd ? e.yearEnd : '',
            monthEnd: touched.monthEnd ? e.monthEnd : '',
            dayEnd: touched.dayEnd ? e.dayEnd : '',
            hourEnd: touched.hourEnd ? e.hourEnd : '',
            minuteEnd: touched.minuteEnd ? e.minuteEnd : '',
            title: touched.title ? e.title : '',
            code: touched.code ? e.code : '',
            type: touched.type ? e.type : '',
            thumbnail: touched.thumbnail ? e.thumbnail : '',
          };
          return (
            <LocalizedForm
              languages={props.languages}
              autoParse={[]}
              initialValues={initialValues}
              localizeInputs={['title']}
            >
              <div style={{ maxWidth: 600 }}>
                <TextField
                  name="title"
                  key="title"
                  label="Survey Title"
                  error={et.title}
                  type="text"
                />
              </div>
              <LayoutItem spaceStackEnd="l" spaceStackStart="l">
                <DatePicker
                  et={et}
                  initialValues={initialValues}
                  suffix="Start"
                  generic
                />
              </LayoutItem>
              <LayoutItem spaceStackEnd="l" spaceStackStart="l">
                <DatePicker
                  et={et}
                  initialValues={initialValues}
                  suffix="End"
                  generic
                />
              </LayoutItem>

              {!context.noUnlockCodes && (
                <TextField
                  name="code"
                  label={'Unlock Code (optional)'}
                  error={et.code}
                />
              )}
              {!props.prevSurvey && (
                <Select name="type" label={'Type'} error={et.type}>
                  <option value="main">Main (maximum 1)</option>
                  <option value="side">Side</option>
                </Select>
              )}

              <div>
                <div style={{ display: 'inline' }}>
                  <UploadButton
                    handleFile={setFieldValue}
                    name="thumbnail"
                    type="image"
                    label={
                      values.thumbnail
                        ? values.thumbnail.name
                        : 'Upload Thumbnail'
                    }
                    error={et.thumbnail}
                  />
                </div>
                {props.prevSurvey?.thumbnail && (
                  <div style={{ display: 'inline' }}>
                    <DownloadButton
                      name={'thumbnail.png'}
                      url={props.prevSurvey.thumbnail || ''}
                    />
                  </div>
                )}
              </div>

              {context.useTag && (
                <div>
                  <Field
                    label={'Tag'}
                    name="tag"
                    as={TextField}
                    autoComplete="off"
                    type="text"
                    validationMessage={errors.tag || ''}
                    inputId={'tag'}
                  />
                  <Text variant="copy2">
                    {!et.title && !values.tag?.length
                      ? getMatchingLang(values.title!, props.languages[0])
                      : undefined}
                  </Text>
                  {getMatchingList(values).length > 0 &&
                    getMatchingList(values).map((t) => (
                      <Button
                        onClick={() => {
                          setFieldValue('tag', t);
                        }}
                      >
                        {t}
                      </Button>
                    ))}
                </div>
              )}

              <LabelButton variant="primary" type="submit">
                Submit Survey
              </LabelButton>
            </LocalizedForm>
          );
        }}
      </Formik>
    </EditDialog>
  );
};
