import React from 'react';
import { Field } from 'formik';
import { SpacingLabel, TextArea as AudiTextArea } from '@audi/audi-ui-react';
import { FormProps } from '../FormProps';
import { NotImplemented } from '../../utils/NotImplemented';
import { TextAreaNeutral } from './TextAreaNeutral';
import { useIsTheme } from '../../theming';

interface TextAreaProps extends FormProps {
  rows?: number;
  disabled?: boolean;
  spacing?: Array<SpacingLabel | undefined>;
}

const TextArea = (props: TextAreaProps) => {
  const theme = useIsTheme();
  if (theme.audi) {
    return (
      <Field
        spacing={props.spacing}
        disabled={props.disabled}
        inputId={props.name}
        label={props.label}
        as={AudiTextArea}
        name={props.name}
        type="text"
        validationMessage={props.error}
        invalid={!!props.error}
        style={{ minWidth: '400px' }}
        rows={props.rows}
      />
    );
  } else if (theme.neutral) {
    return <TextAreaNeutral {...props} />;
  } else {
    return <NotImplemented />;
  }
};

export { TextAreaProps, TextArea };
