import React from 'react';
import { Field } from 'formik';
import { TextField as AudiTextField } from '@audi/audi-ui-react';
import { FormProps } from '../FormProps';
import { NeutralTextField } from './NeutralTextField';
import { NotImplemented } from '../../utils/NotImplemented';
import { useIsTheme } from '../../theming';

export type TextFieldTypes = 'text' | 'password' | 'number';
export type AutoComplete =
  | 'off'
  | 'username'
  | 'current-password'
  | 'new-password'
  | 'one-time-code';

interface TextFieldProps extends FormProps {
  type?: TextFieldTypes;
  disabled?: boolean;
  autoComplete?: AutoComplete;
}

const TextField = (props: TextFieldProps) => {
  const theme = useIsTheme();
  if (theme.audi) {
    return (
      <Field
        inputId={props.name}
        label={props.label}
        as={AudiTextField}
        disabled={props.disabled}
        name={props.name}
        type={props.type || 'text'}
        validationMessage={props.error}
        invalid={!!props.error}
        autoComplete={props.autoComplete}
      />
    );
  } else if (theme.neutral) {
    return (
      <NeutralTextField
        inputId={props.name}
        {...props}
        dark={theme.neutralDark}
      />
    );
  } else {
    return <NotImplemented />;
  }
};

export { TextFieldProps, TextField };
