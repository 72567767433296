import React from 'react';
import { RadioButton as AudiRadio } from '@audi/audi-ui-react';
import { FormProps } from './FormProps';

interface RadioProps extends FormProps {
  handle: (name: string, selected: boolean) => void;
  value: string;
  default?: boolean;
  readonly?: boolean;
  disabledState?: boolean;
}

const Radio = (props: RadioProps) => {
  const handleInput = () => {
    props.handle(props.name, props.value === 'true');
  };

  const disabled = props.disabledState !== undefined;
  const checked =
    props.disabledState !== undefined ? props.disabledState : undefined;

  return (
    <div style={{ display: 'inline-block', marginRight: '20px' }}>
      <AudiRadio
        name={props.name}
        inputId={props.name}
        validationMessage={props.error}
        invalid={!!props.error}
        onChange={handleInput}
        disabled={disabled}
        checked={checked}
        defaultChecked={props.default}
      >
        {props.label}
      </AudiRadio>
    </div>
  );
};

export { RadioProps, Radio };
