import React from 'react';
import { SvgIcon } from '@material-ui/core';

const LanguageIconAudi = () => (
  <SvgIcon fontSize="inherit" fill="currentColor">
    <path
      d="M1.7,16.5 L21.3,16.5 M0.5,11.5 L22.5,11.5 M1.7,6.5 L21.3,6.5 M11.5,22.5 C14.2614237,22.5 16.5,17.5751322 16.5,11.5 C16.5,5.42486775 14.2614237,0.5 11.5,0.5 C8.73857625,0.5 6.5,5.42486775 6.5,11.5 C6.5,17.5751322 8.73857625,22.5 11.5,22.5 Z M11.5,22.5 C17.5751322,22.5 22.5,17.5751322 22.5,11.5 C22.5,5.42486775 17.5751322,0.5 11.5,0.5 C5.42486775,0.5 0.5,5.42486775 0.5,11.5 C0.5,17.5751322 5.42486775,22.5 11.5,22.5 Z"
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export { LanguageIconAudi };
