import React, { CSSProperties } from 'react';
import { TableCell as MuiTableCell } from '@material-ui/core';

interface SessionCellProps {
  style?: CSSProperties;
  image?: boolean;
  title?: boolean;
  controls?: boolean;
}

const SessionTableCell: React.FC<SessionCellProps> = (props) => {
  const s: CSSProperties = props.style || {};
  if (props.image) {
    s['width'] = '140px';
    s['padding'] = '0';
    s['height'] = '80px';
    s['verticalAlign'] = 'center';
  }
  if (props.title) {
    s['width'] = '50%';
  }
  if (props.controls) {
    s['textAlign'] = 'right';
  }
  s['verticalAlign'] = 'center';
  return (
    <MuiTableCell align="left" style={s}>
      {props.children}
    </MuiTableCell>
  );
};

export { SessionTableCell, SessionCellProps };
