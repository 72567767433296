import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormProps } from './FormProps';
import { Text } from '../base/Text/Text';
import { LabelButton } from '../utils/LabelButton/LabelButton';

const useStyles = makeStyles({
  error: {
    color: '#BB0A30',
  },
});

interface NewsVideoUploadButtonProps extends FormProps {
  handleFile: (name: string, file: File) => void;
}

const NewsVideoUploadButton = (props: NewsVideoUploadButtonProps) => {
  const hiddenFileInput = React.useRef(null);
  const cls = useStyles();

  const handleClick = () => {
    // @ts-ignore
    hiddenFileInput.current.click();
  };

  const handleChange = (event: any) => {
    const fileUploaded = event.target.files[0];
    props.handleFile(props.name, fileUploaded);
  };

  return (
    <div>
      <LabelButton variant="secondary" onClick={handleClick} spaceInlineEnd="l">
        {props.label}
      </LabelButton>
      <Text as="p" variant="copy2" className={cls.error}>
        {props.error}
      </Text>
      <input
        type="file"
        accept={'video/mp4'}
        ref={hiddenFileInput}
        onChange={handleChange}
        onClick={(event) => {
          event.currentTarget.value = ''; // Allows handleChange to be called when selecting the same file again
          handleChange(event);
        }}
        style={{ display: 'none' }}
      />
    </div>
  );
};

export { NewsVideoUploadButton, NewsVideoUploadButtonProps };
