import React, { FC } from 'react';
import { makeThemedStyles, useThemedStyles } from '../utils/makeThemedStyles';

const styles = makeThemedStyles({
  wrapper: {
    flexGrow: 1,
    width: '100%',
    background: ({ colorScheme }) => colorScheme.background,
  },
});

export const TabWrapper: FC = (props) => {
  const cls = useThemedStyles(styles);
  return <div className={cls.wrapper}>{props.children}</div>;
};
