import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const ArrowUpDown = () => {
  return (
    <div style={{ display: 'inline-block' }}>
      <div>
        <SvgIcon fontSize="inherit">
          <path
            d="M18 15.5L12.5 10 7 15.5"
            stroke="#333333"
            strokeWidth="2"
            fill="none"
            fillRule="evenodd"
          />
        </SvgIcon>
      </div>
      <div>
        <SvgIcon fontSize="inherit">
          <path
            d="M7 10L12.5 15.5 18 10"
            stroke="#333333"
            strokeWidth="2"
            fill="none"
            fillRule="evenodd"
          />
        </SvgIcon>
      </div>
    </div>
  );
};
