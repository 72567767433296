import React from 'react';
import { SvgIcon } from '@material-ui/core';

const PublicIconAudi = () => (
  <SvgIcon fontSize="inherit" viewBox="0 0 24 24">
    {' '}
    <g id="groups-small" transform="translate(1 2.5)">
      <path
        id="Shape"
        d="M3,7h7.5V1a1,1,0,0,0-1-1H0"
        transform="translate(11 9.5)"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <rect
        id="Rectangle-path"
        width="6"
        height="6"
        rx="2"
        transform="translate(12.5 0.5)"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <path
        id="Shape-2"
        data-name="Shape"
        d="M0,7V1A1,1,0,0,1,1,0H11a1,1,0,0,1,1,1V7Z"
        transform="translate(0.5 11.5)"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <rect
        id="Rectangle-path-2"
        data-name="Rectangle-path"
        width="6"
        height="6"
        rx="2"
        transform="translate(3.5 2.5)"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </g>
    <rect
      id="Rechteck_56"
      data-name="Rechteck 56"
      width="24"
      height="24"
      fill="none"
    />
  </SvgIcon>
);

export { PublicIconAudi };
