import React from 'react';
import { NotImplemented } from '../../utils/NotImplemented';
import { useIsTheme } from '../../theming';
import { CancelIconAudi } from './CancelIconAudi';
import { CancelIconNeutral } from './CancelIconNeutral';

const CancelIcon = () => {
  const theme = useIsTheme();
  if (theme.audi) {
    return <CancelIconAudi />;
  } else if (theme.neutral) {
    return <CancelIconNeutral />;
  } else return <NotImplemented />;
};
/** @deprecated Use CancelIcon instead */
export const CancelIconWrapper = CancelIcon;
export { CancelIcon };
