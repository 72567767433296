import React from 'react';
import { SvgIcon } from '@material-ui/core';

const EraseIcon = () => (
  <SvgIcon fontSize="inherit">
    <path
      d="M5.5,9 L5.5,19.5 C5.5,20 5.9,20.5 6.5,20.5 L16.5,20.5 C17,20.5 17.5,20 17.5,19.5 L17.5,9 M11.5,5.5 L11.5,3.5 M10,3.5 L13,3.5 M3.5,7.5 L19.5,7.5 L18.1,5.5 L4.9,5.5 C4.9,5.5 3.5,7.5 3.5,7.5 Z"
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export { EraseIcon };
