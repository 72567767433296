import React from 'react';
import { Select as AudiSelect } from '@audi/audi-ui-react';
import { FormProps } from './FormProps';
import { Field } from 'formik';

interface SelectProps extends FormProps {
  required?: boolean;
}

const Select: React.FC<SelectProps> = (props) => {
  return (
    <Field
      inputId={props.name}
      label={props.label}
      as={AudiSelect}
      name={props.name}
      required={props.required}
      type="select"
      validationMessage={props.error}
      invalid={!!props.error}
    >
      {props.children}
    </Field>
  );
};

export { SelectProps, Select };
