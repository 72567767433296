import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const PlayIconAudi = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" viewBox="0 0 48 48" {...props}>
    <g id="system-play-large" transform="translate(2 2)">
      <path
        id="Shape"
        d="M0,0,10.209,6.835,0,13.855Z"
        transform="translate(18.089 15.072)"
        fill="none"
        stroke="#ffffff"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        strokeWidth="1"
      />
      <circle
        id="Oval"
        cx="21.511"
        cy="21.511"
        r="21.511"
        transform="translate(0.489 0.489)"
        fill="none"
        stroke="#ffffff"
        stroke-miterlimit="10"
        strokeWidth="1"
      />
    </g>
  </SvgIcon>
);

export { PlayIconAudi };
