import React from 'react';
import { NotImplemented } from '../../utils/NotImplemented';
import { useIsTheme } from '../../theming';
import { PrivateIconDark } from './PrivateIconDark';
import { PrivateIconAudi } from './PrivateIconAudi';

const PrivateIcon = () => {
  const theme = useIsTheme();
  if (theme.neutralDark) {
    return <PrivateIconDark />;
  } else if (!theme.neutralDark) {
    return <PrivateIconAudi />;
  } else return <NotImplemented />;
};

export { PrivateIcon };
