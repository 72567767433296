import React, { FC } from 'react';
import { Field } from 'formik';
import { TextAreaProps } from './TextArea';
import {
  makeThemedStyles,
  useThemedStyles,
} from '../../utils/makeThemedStyles';
import { NeutralText } from '../../base/Text/NeutralText';

const styles = makeThemedStyles({
  neutralTextField: {
    fontFamily: 'inherit',
    outline: 'none',
    padding: '10px 8px',
    width: '100%',
    fontSize: '16px',
    background: ({ isTheme }) => (isTheme.neutralLight ? '#ffffff' : '#272727'),
    border: ({ isTheme }) =>
      isTheme.neutralLight ? '2px solid #D5D5D5' : '2px solid #363434',
    color: ({ isTheme }) => (isTheme.neutralLight ? '' : '#ffffff'),
    minHeight: '120px',
    lineHeight: '1.3',
    '&:hover': {
      border: ({ isTheme }) =>
        isTheme.neutralLight ? '2px solid #BABABA' : '2px solid #818181',
    },
    '&:-webkit-autofill': {
      '-webkit-box-shadow': ({ isTheme }) =>
        isTheme.neutralLight
          ? '0 0 0 100px #ffffff inset'
          : '0 0 0 100px #272727 inset',
      '-webkit-text-fill-color': ({ isTheme }) =>
        isTheme.neutralLight ? '' : '#a9a5a5',
    },
  },
  margin: {
    marginTop: '4px',
  },
});

interface NeutralTextArea extends TextAreaProps {
  error?: string;
}

export const TextAreaNeutral: FC<NeutralTextArea> = (p) => {
  const cls = useThemedStyles(styles);
  const props = {
    ...p,
  };
  return (
    <div>
      <Field
        spacing={props.spacing}
        disabled={props.disabled}
        name={props.name}
        placeholder={props.label}
        rows={props.rows}
        as={'textarea'}
        className={cls.neutralTextField}
      />
      {props.error && (
        <div className={cls.margin}>
          <NeutralText error={true}> {props.error}</NeutralText>
        </div>
      )}
    </div>
  );
};
