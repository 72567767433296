import React from 'react';
import { makeStyles, SvgIcon } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  svgWrapper: {
    fontSize: '30px',
    color: '#7C7C7C',
    cursor: 'pointer',
    '&:hover': {
      color: '#ffffff',
    },
  },
}));
const MyScheduleIconDark = () => {
  const cls = useStyles();
  return (
    <SvgIcon className={cls.svgWrapper}>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path
        d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V10h16v11zm0-13H4V5h16v3z"
        stroke="currentColor"
        strokeWidth="0"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export { MyScheduleIconDark };
