import { LayoutItem } from '@audi/audi-ui-react';
import React, { CSSProperties, FC } from 'react';
import clsx from 'clsx';
// Import can't be shortened as it causes a circular dependency
import {
  makeThemedStyles,
  useThemedStyles,
} from '../../utils/makeThemedStyles';
import { AllTextProps } from './Text';

const styles = makeThemedStyles((theme) => {
  return {
    display1: {
      [theme.breakpoints.only('xs')]: {
        fontSize: '40px',
        lineHeight: '46px',
      },
      [theme.breakpoints.only('sm')]: {
        fontSize: '52px',
        lineHeight: '56px',
      },
      [theme.breakpoints.only('md')]: {
        fontSize: '70px',
        lineHeight: '70px',
      },
      [theme.breakpoints.only('lg')]: {
        fontSize: '90px',
        lineHeight: '90px',
      },
      [theme.breakpoints.only('xl')]: {
        fontSize: '110px',
        lineHeight: '110px',
      },
    },

    display2: {
      [theme.breakpoints.down('xs')]: {
        fontSize: '34px',
        lineHeight: '40px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '34px',
        lineHeight: '40px',
      },
      [theme.breakpoints.only('md')]: {
        fontSize: '40px',
        lineHeight: '46px',
      },
      [theme.breakpoints.only('lg')]: {
        fontSize: '52px',
        lineHeight: '56px',
      },
      [theme.breakpoints.only('xl')]: {
        fontSize: '52px',
        lineHeight: '56px',
      },
    },

    order1: {
      [theme.breakpoints.down('xs')]: {
        fontSize: '34px',
        lineHeight: '40px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '34px',
        lineHeight: '40px',
      },
      [theme.breakpoints.only('md')]: {
        fontSize: '40px',
        lineHeight: '46px',
      },
      [theme.breakpoints.only('lg')]: {
        fontSize: '52px',
        lineHeight: '56px',
      },
      [theme.breakpoints.only('xl')]: {
        fontSize: '52px',
        lineHeight: '56px',
      },
    },

    order2: {
      [theme.breakpoints.down('xs')]: {
        fontSize: '34px',
        lineHeight: '40px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '34px',
        lineHeight: '40px',
      },
      [theme.breakpoints.only('md')]: {
        fontSize: '40px',
        lineHeight: '46px',
      },
      [theme.breakpoints.only('lg')]: {
        fontSize: '52px',
        lineHeight: '56px',
      },
      [theme.breakpoints.only('xl')]: {
        fontSize: '52px',
        lineHeight: '56px',
      },
    },

    order3: {
      [theme.breakpoints.down('xs')]: {
        fontSize: '26px',
        lineHeight: '32px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '26px',
        lineHeight: '32px',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '26px',
        lineHeight: '32px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: '34px',
        lineHeight: '40px',
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '34px',
        lineHeight: '40px',
      },
    },

    order4: {
      [theme.breakpoints.down('xs')]: {
        fontSize: '18px',
        lineHeight: '24px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
        lineHeight: '24px',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '18px',
        lineHeight: '24px',
      },
      [theme.breakpoints.only('lg')]: {
        fontSize: '22px',
        lineHeight: '28px',
      },
      [theme.breakpoints.only('xl')]: {
        fontSize: '24px',
        lineHeight: '32px',
      },
    },

    copy1: {
      fontSize: '16px',
      lineHeight: '24px',
      [theme.breakpoints.only('lg')]: {
        fontSize: '18px',
        lineHeight: '26px',
      },
      [theme.breakpoints.only('xl')]: {
        fontSize: '18px',
        lineHeight: '26px',
      },
    },

    copy2: {
      fontSize: '13px',
      lineHeight: '20px',
    },

    copy3: {
      fontSize: '12px',
      lineHeight: '20px',
    },
    color: {
      color: ({ colorScheme }) => colorScheme.text,
      '& a': {
        color: ({ colorScheme }) => colorScheme.text,
        textUnderlineOffset: '5px',
      },
    },
  };
});

interface NeutralTextProps extends AllTextProps {
  error?: boolean;
  customFontWeight?: string;
}

export const NeutralText: FC<NeutralTextProps> = (p) => {
  const cls = useThemedStyles(styles);
  const props = {
    ...p,
    as: p.as || 'p',
    variant: p.variant || 'copy1',
  };
  const style: CSSProperties = {
    fontWeight: props.weight || 'normal',
    fontFamily: "'Outfit', sans-serif",
    margin: 0,
    color: props.error ? 'red' : props.black ? 'black' : undefined,
  };

  if (
    props.spacing ||
    props.spaceStackStart ||
    props.spaceStackEnd ||
    props.spaceInlineStart ||
    props.spaceInlineEnd
  ) {
    return (
      <LayoutItem
        spacing={
          props.spacing || [
            props.spaceStackStart,
            props.spaceInlineEnd,
            props.spaceStackEnd,
            props.spaceInlineStart,
          ]
        }
        align="auto"
      >
        <props.as
          style={style}
          className={clsx(cls[props.variant], props.className, cls.color)}
        >
          {props.children}
        </props.as>
      </LayoutItem>
    );
  } else {
    return (
      <props.as
        style={style}
        className={clsx(cls[props.variant], props.className, cls.color)}
      >
        {props.children}
      </props.as>
    );
  }
};
