import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const EditIcon = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" {...props}>
    <path
      d="M11.8,4.2 L18.4,7.3 L15.8,12.8 M5.5,17.8 L9.1,19.5 M5.5,17.8 L5.5,22.5 L9.1,19.5 L16.6,3.5 C16.8,3.2 16.6,2.8 16.3,2.6 L13.9,1.5 C13.6,1.3 13.2,1.5 13,1.8 L5.5,17.8 Z"
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export { EditIcon };
