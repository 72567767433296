import { makeStyles } from '@material-ui/core/styles';
import { ThemeConfig, useThemeConfig } from '../theming/Hooks';
import {
  ClassNameMap,
  WithStylesOptions,
} from '@material-ui/styles/withStyles';
import { Theme as DefaultTheme } from '@material-ui/core/styles/createMuiTheme';
import { Omit } from '@material-ui/types';
import { Styles } from '@material-ui/core/styles/withStyles';

type StyleParam<ClassKey extends string> = Styles<
  DefaultTheme,
  ThemeConfig,
  ClassKey
>;
type Return<ClassKey extends string> = keyof ThemeConfig extends never
  ? (props?: any) => ClassNameMap<ClassKey>
  : (props: ThemeConfig) => ClassNameMap<ClassKey>;

type Options = Omit<WithStylesOptions<DefaultTheme>, 'withTheme'>;

/** Working analog to makeStyles, but automatically sets the Theme and Props */
export const makeThemedStyles = <ClassKey extends string>(
  styles: StyleParam<ClassKey>,
  options?: Options
): Return<ClassKey> => makeStyles(styles, options);

/** Instead of directly calling const cls = useStyles()
 * you can call useThemedStyles(useStyles) to enrich it with the current theme */
export const useThemedStyles = <ClassKey extends string>(
  stylesFunction: Return<ClassKey>
): ClassNameMap<ClassKey> => {
  const themeConfig = useThemeConfig();
  return stylesFunction(themeConfig);
};
