import React from 'react';
import { NotImplemented } from '../../utils/NotImplemented';
import { useIsTheme } from '../../theming';
import { NotificationIconLight } from './NotificationIconLight';
import { NotificationIconDark } from './NotificationIconDark';
import { NotificationIconAudi } from './NotificationIconAudi';

const NotificationIcon = () => {
  const theme = useIsTheme();

  if (theme.neutralDark) {
    return <NotificationIconDark />;
  } else if (theme.neutralLight) {
    return <NotificationIconLight />;
  } else if (theme.audi) {
    return <NotificationIconAudi />;
  } else return <NotImplemented />;
};

export { NotificationIcon };
