import React from 'react';
import { useIsTheme } from '../../theming/Hooks';
import { NotImplemented } from '../../utils/NotImplemented';
import { PollClearIconAudi } from './PollClearIconAudi';
import { PollClearIconDark } from './PollClearIconDark';
import { PollClearIconLight } from './PollClearIconLight';

const PollClearIcon = () => {
  const theme = useIsTheme();
  if (theme.audi) {
    return <PollClearIconAudi />;
  } else if (theme.neutralLight) {
    return <PollClearIconLight />;
  } else if (theme.neutralDark) {
    return <PollClearIconDark />;
  } else return <NotImplemented />;
};
export { PollClearIcon };
