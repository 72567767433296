import { makeStyles } from '@material-ui/core';
import {
  NativeCheckbox as Checkbox,
  Text,
  NativeTextArea as TextArea,
  useIsTheme,
} from '@pp-labs/ui-components';
import React, { useState } from 'react';
import { CurrentSurveyValues, ParsedMcSurveyText } from './Interfaces';
import Stars from './Stars';
import { SurveyStrings } from './strings';
import {
  getLocalizedValue,
  getMatchingLang,
  jsonToSurveyText,
  useSurveyContext,
} from './utils';

const paddings = ['18px 0 6px 0', '18px 0 6px 18px', '18px 0 6px 32px'];

interface ObjectProps {
  strings: SurveyStrings;
  item: CurrentSurveyValues;
  index: number;
  disabled: boolean;
  emit: (index: number, value: string) => void;
}

export const SurveyObject = (props: ObjectProps) => {
  const layer = props.item.indentParent;

  return (
    <div
      key={props.item.surveyTemplateItemId}
      style={{ padding: paddings[layer] }}
    >
      {layer === 0 && <div style={{ paddingTop: 22 }} />}
      <SurveyField {...props} />
    </div>
  );
};

interface FieldProps extends ObjectProps {}

const surveyFieldStyles = makeStyles({
  inlineText: { display: 'inline-block' },
});

const layers: Array<'order3' | 'order4' | 'copy1'> = [
  // "order3",
  'order4',
  'copy1',
  'copy1',
  'copy1',
];
const getFrontWeight = (item: CurrentSurveyValues): 'bold' | 'normal' =>
  item.surveyType === 'headline' ? 'bold' : 'normal';

const SurveyField = (props: FieldProps) => {
  const context = useSurveyContext();
  const cls = surveyFieldStyles();
  const theme = useIsTheme();
  const l = props.item.indentParent;
  const parsedMc: ParsedMcSurveyText | null =
    props.item.surveyType === 'mc'
      ? jsonToSurveyText(props.item.surveyText)
      : null;

  const text = parsedMc
    ? getMatchingLang(parsedMc.text, context.strings.getLanguage())
    : getLocalizedValue(props.item.surveyText, context.strings.getLanguage());

  const head = (
    <Text
      className={l === 0 ? cls.inlineText : ''}
      as="h3"
      variant={layers[l]}
      weight={getFrontWeight(props.item)}
      spaceStackEnd={'s'}
    >
      {text}
    </Text>
  );

  const tailText = getLocalizedValue(
    props.item?.additionalText,
    context.strings.getLanguage()
  );
  const tail =
    tailText.length > 0 && props.disabled ? (
      <Text
        className={l === 0 ? cls.inlineText : ''}
        as="p"
        variant="copy2"
        spaceStackEnd={'s'}
      >
        {tailText}
      </Text>
    ) : null;
  if (props.item.surveyType === 'headline') {
    return (
      <>
        {head}
        {tail}
      </>
    );
  } else if (props.item.surveyType === 'rating') {
    return (
      <>
        {head}
        <div>
          <Stars
            identifier={props.index}
            emit={(index: number, value: number) =>
              props.emit(index, value.toString())
            }
            value={Number(props.item.currentVal || '0')}
            disabled={props.disabled}
            maxStars={context.maxStars}
          />
        </div>
        {tail}
      </>
    );
  } else if (props.item.surveyType === 'mc') {
    return (
      <>
        {head}
        {parsedMc && (
          <McCheckboxes
            parsed={parsedMc}
            item={props.item}
            emit={props.emit}
            index={props.index}
            disabled={props.disabled}
          />
        )}
        {tail}
      </>
    );
  } else if (props.item.surveyType === 'text') {
    return (
      <>
        {head}
        <div style={{ maxWidth: 600 }}>
          {props.disabled ? (
            <Text as="p" variant="copy1">
              {props.item.currentVal}
            </Text>
          ) : (
            <TextArea
              inputId={`survey-in-${props.item.surveyTemplateItemId}`}
              className={
                theme.neutralDark
                  ? 'survey-textarea-dark'
                  : theme.neutralLight
                  ? 'survey-textarea-light'
                  : ''
              }
              rows={2}
              label={props.strings.typeKeyMessage}
              disabled={false}
              onChange={(e) => props.emit(props.index, e.target.value)}
            />
          )}
          {tail}
        </div>
      </>
    );
  }
  return null;
};

const McCheckboxes = (props: {
  parsed: ParsedMcSurveyText;
  item: CurrentSurveyValues;
  emit: (index: number, value: string) => void;
  index: number;
  disabled: boolean;
}) => {
  const context = useSurveyContext();
  const theme = useIsTheme();
  const getInitialValue = (): boolean[] => {
    const arr = new Array(props.parsed.answers.length).fill(false);
    if (props.item.currentVal) {
      const parsed: number[] = JSON.parse(props.item.currentVal);
      return arr.map((_, i) => parsed.includes(i));
    } else {
      return arr;
    }
  };
  const [ticked, setTicked] = useState<boolean[]>(getInitialValue());

  const onChange = (i: number) => {
    const copy = ticked.slice();
    copy[i] = !copy[i];
    setTicked(copy);
    const emitted: number[] = [];
    copy.forEach((c, i) => {
      if (c) emitted.push(i);
    });
    props.emit(props.index, JSON.stringify(emitted));
  };

  return (
    <div>
      {props.parsed.answers.map((a, i) => (
        <div key={i} style={{ paddingBottom: 8 }}>
          <div
            className={
              theme.neutralDark ? 'darkCheckbookWrapperSurvey' : undefined
            }
          >
            <Checkbox
              inputId={`answer.${props.item.surveyTemplateItemId}.${i}`}
              onChange={() => onChange(i)}
              checked={ticked[i]}
              disabled={props.disabled}
            >
              {getMatchingLang(a, context.strings.getLanguage())}
            </Checkbox>
          </div>
        </div>
      ))}
    </div>
  );
};
