import React from 'react';
import { SvgIcon } from '@material-ui/core';

const ThumbUpIconOutlineAudi = () => (
  <SvgIcon fontSize="inherit">
    <path
      d="M16.9413,21.3113 L12.3093,21.3113 C10.4873,21.3113 9.0663,20.7813 7.8013,20.2063 L7.4403,20.0403 C6.4953,19.6063 5.7493,19.2643 5.0053,19.2643 L2.2013,19.2643 C1.7413,19.2643 1.3443,18.9363 1.2403,18.4653 C0.9843,17.2793 0.8493,15.8743 0.8493,14.3933 C0.8493,12.9173 0.9843,11.5143 1.2373,10.3333 C1.3423,9.8543 1.7383,9.5213 2.2013,9.5213 L5.2003,9.5213 C7.0503,8.3803 8.1413,6.5213 8.7363,5.1513 C9.1323,4.2333 9.3553,3.4523 9.4533,3.0673 C9.5293,2.7943 9.5793,2.5763 9.6093,2.4223 L9.6303,2.3003 C9.6383,2.2533 9.6383,2.2323 9.6543,2.1483 C9.8663,1.0843 10.7783,0.3113 11.8233,0.3113 C13.0403,0.3113 14.0303,1.3493 14.0303,2.6243 L14.0303,7.2163 L17.9113,7.2163 C19.3963,7.2163 20.6033,8.6003 20.6033,10.2983 C20.6033,10.7443 20.5063,11.1783 20.3073,11.6143 C20.6643,12.1533 20.8493,12.7543 20.8493,13.3733 C20.8493,14.0113 20.6483,14.6353 20.2633,15.1913 C20.3333,15.4503 20.3653,15.6913 20.3653,15.9303 C20.3653,16.8243 19.9853,17.6513 19.3533,18.1763 C19.3733,18.2713 19.3853,18.3743 19.3853,18.4883 C19.3853,20.0453 18.2883,21.3113 16.9413,21.3113"
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export { ThumbUpIconOutlineAudi };
