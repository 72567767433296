import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useIsTheme } from '../../theming';

const useStyle = makeStyles({
  common: {
    height: 48,
    width: 48,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    fontFamily: 'inherit',
    fontSize: '18px',
    marginTop: '4px',
    marginBottom: '4px',
  },
  audiActive: {
    backgroundColor: '#000000',
    color: '#FFFFFF',
  },
  audiInactive: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    border: 'solid 1px',
  },
  lightActive: {
    background: '#000000',
    color: '#ffffff',
  },
  lightInactive: {
    background: '#EFEFEF',
    color: '#000000',
    border: 'solid 1px',
  },
  darkActive: {
    background: '#FFFFFF',
    color: '#000000',
  },
  darkInactive: {
    background: '#151515',
    color: '#ffffff',
    border: 'solid 1px',
  },
});

interface P {
  text: 'A' | 'B' | 'C';
  active: boolean;
}
const AbcIcon = (props: P) => {
  const theme = useIsTheme();
  const cls = useStyle();
  if (props.active) {
    return (
      <div
        className={clsx(
          cls.common,
          theme.audi
            ? cls.audiActive
            : theme.neutralLight
            ? cls.lightActive
            : cls.darkActive
        )}
      >
        {props.text}
      </div>
    );
  } else {
    return (
      <div
        className={clsx(
          cls.common,
          theme.audi
            ? cls.audiInactive
            : theme.neutralLight
            ? cls.lightInactive
            : cls.darkInactive
        )}
      >
        {props.text}
      </div>
    );
  }
};
export { AbcIcon };
