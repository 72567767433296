import {
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
} from '@material-ui/core';
import {
  DraggableComponent,
  DroppableComponent,
  EditIcon,
  EraseIcon,
  handleDrop,
  LabelButton,
  TableCell,
  TableRow,
  tableStyles,
} from '@pp-labs/ui-components';
import { Text } from '@pp-labs/ui-components';
import React, { useEffect, useState } from 'react';
import { GetSurveyTemplateItem } from '../Interfaces';
import {
  getIndention,
  getLocalizedValue,
  getMatchingLang,
  jsonToSurveyText,
  sortItems,
  useSurveyContext,
} from '../utils';
import { AddOutlined } from '@material-ui/icons';
import { EditSurveyItem } from './EditSurveyItem';
import { ArrowUpDown } from './ArrowUpDown';

interface P {
  items: GetSurveyTemplateItem[];
  refresh: () => void;
  surveyTemplateId: number;
  isTemplate: boolean;
  languages: string[];
}

const useStyles = makeStyles({
  ...tableStyles(),
});

interface Editing {
  id: number;
  indent: number | undefined;
  position: number | undefined;
}

const maxPosition = (items: GetSurveyTemplateItem[]) =>
  items.map((i) => i.position).reduce((acc, curr) => Math.max(acc, curr), 0);

const SurveyTable = (props: P) => {
  const cls = useStyles();
  const context = useSurveyContext();
  const [currentlyEditing, setCurrentlyEditing] =
    useState<Editing | null>(null);
  const [myItems, setMyItems] = useState<GetSurveyTemplateItem[]>([]);

  const setItems = (items: GetSurveyTemplateItem[]) =>
    setMyItems(sortItems(items));

  useEffect(() => {
    setItems(props.items);
  }, [props.items]);

  const onDragEnd = (result: any) => {
    const newItems = handleDrop(result, myItems);
    if (newItems)
      setItems(newItems.map((i, index) => ({ ...i, position: index })));
  };

  const confirmOrder = async () => {
    const newOrder: number[] = myItems.map((i) => i.surveyTemplateItemId);
    await context.client.post(
      `${context.endpoints.postTemplate}/items/order`,
      newOrder
    );
    context.notify('Order successfully changed', 'success');
    props.refresh();
  };

  const edit = (id: number) =>
    setCurrentlyEditing({ id: id, indent: undefined, position: undefined });

  const closeEdit = (refresh: boolean) => {
    if (refresh) props.refresh();
    setCurrentlyEditing(null);
  };

  const createNew = (indent: number, position: number) =>
    setCurrentlyEditing({ id: 0, indent: indent, position: position });

  const createIndented = (item: GetSurveyTemplateItem) => {
    const max = maxPosition(
      myItems.filter((i) => i.indentParent === item.surveyTemplateItemId)
    );
    createNew(item.surveyTemplateItemId, Math.max(max, item.position) + 1);
  };

  const del = async (id: number) => {
    await context.client.delete(`${context.endpoints.postTemplate}/item/${id}`);
    props.refresh();
  };

  return (
    <div>
      <TableContainer>
        <Table className={cls.table} aria-label="simple table">
          <TableBody component={DroppableComponent(onDragEnd)}>
            {myItems.map((item, index) => {
              const id = item.surveyTemplateItemId;
              const indention = getIndention(item, myItems);
              return (
                <TableRow
                  component={DraggableComponent(id.toString(), index)}
                  key={id}
                >
                  <TableCell
                    center
                    style={{ paddingLeft: `${(indention + 1) * 24}px` }}
                  >
                    <Text variant="copy3">Survey item type</Text>
                    <Text as="p" variant="copy1">
                      {item.surveyType}
                    </Text>
                  </TableCell>

                  <TableCell center>
                    <Text variant="copy3">Survey Question</Text>
                    <Text as="p" variant="copy1">
                      {item.surveyType === 'mc'
                        ? getMatchingLang(
                            jsonToSurveyText(item.surveyText)?.text || [],
                            'English'
                          )
                        : getLocalizedValue(item.surveyText, 'English')}
                    </Text>
                  </TableCell>

                  {context.importerLogic && (
                    <TableCell center>
                      <Text as="p" variant="copy1">
                        {item.surveyGroup}
                      </Text>
                    </TableCell>
                  )}

                  {props.isTemplate ? (
                    <TableCell buttons />
                  ) : (
                    <TableCell buttons>
                      <IconButton disabled style={{ padding: 0 }}>
                        <ArrowUpDown />
                      </IconButton>
                      {indention < 2 && (
                        <IconButton>
                          <AddOutlined onClick={() => createIndented(item)} />
                        </IconButton>
                      )}

                      <IconButton onClick={() => edit(id)}>
                        <EditIcon />
                      </IconButton>

                      <IconButton
                        onClick={() => del(item.surveyTemplateItemId)}
                      >
                        <EraseIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {!props.isTemplate && (
        <>
          <LabelButton
            variant="secondary"
            onClick={() => createNew(0, maxPosition(myItems) + 1)}
            className={cls.btnLeft}
          >
            Create new Top Layer Item
          </LabelButton>
          <LabelButton
            variant="secondary"
            onClick={confirmOrder}
            className={cls.btnRight}
          >
            Confirm new Order
          </LabelButton>
        </>
      )}
      {currentlyEditing !== null && (
        <EditSurveyItem
          item={
            currentlyEditing
              ? myItems.find(
                  (i) => i.surveyTemplateItemId === currentlyEditing.id
                ) || null
              : null
          }
          indent={currentlyEditing.indent}
          surveyTemplateId={props.surveyTemplateId}
          close={closeEdit}
          position={currentlyEditing.position}
          languages={props.languages}
        />
      )}
    </div>
  );
};

export { SurveyTable };
