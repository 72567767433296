import { TextFieldProps } from '@audi/audi-ui-react';
import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'formik';
import { AutoComplete, TextFieldTypes } from './TextField';
import { NeutralText } from '../../base/Text/NeutralText';

const useStyles = makeStyles(() => ({
  lightTextField: {
    fontFamily: "'Outfit', sans-serif",
    outline: 'none',
    padding: '16px 8px',
    width: '100%',
    fontSize: '16px',
    background: '#ffffff',
    border: '2px solid #D5D5D5',
    '&:hover': {
      border: '2px solid #BABABA',
    },
    '&:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 100px #ffffff inset',
    },
  },
  darkTextField: {
    fontFamily: "'Outfit', sans-serif",
    outline: 'none',
    padding: '16px 8px',
    width: '100%',
    fontSize: '16px',
    background: '#272727',
    color: '#ffffff',
    border: '2px solid #363434',
    '&:hover': {
      border: '2px solid #818181',
    },
    '&:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 100px #272727 inset',
      '-webkit-text-fill-color': '#a9a5a5',
    },
  },
  margin: {
    marginTop: '10px',
  },
}));

interface NeutralTextFieldProps extends TextFieldProps {
  error?: any;
  dark?: boolean;
  type?: TextFieldTypes;
  disabled?: boolean;
  autoComplete?: AutoComplete;
}

export const NeutralTextField: FC<NeutralTextFieldProps> = (p) => {
  const cls = useStyles();
  const props = {
    ...p,
  };
  return (
    <div>
      <Field
        disabled={props.disabled}
        name={props.name}
        type={props.type || 'text'}
        autoComplete={props.autoComplete}
        className={props.dark ? cls.darkTextField : cls.lightTextField}
        placeholder={props.label}
      />
      {props.error && (
        <div className={cls.margin}>
          <NeutralText error={true}> {props.error}</NeutralText>
        </div>
      )}
    </div>
  );
};
