import React, { useEffect, useMemo, useState } from 'react';
import { Field, FieldArray, Formik } from 'formik';
import * as Yup from 'yup';
import {
  allGroups,
  GetSurveyTemplateItem,
  Groups,
  ParsedMcSurveyText,
  PostSurveyTemplateItem,
  SurveyType,
} from '../Interfaces';
import {
  EditDialog,
  LabelButton,
  LocalizedForm,
  Select,
  TextArea,
  TextField,
  Text,
} from '@pp-labs/ui-components';
import {
  atLeastOneLanguage,
  getLocalizedValue,
  jsonToSurveyText,
  matchingList,
  MultiLanguageString,
  parseLanguageJson,
  stringifyLanguageJson,
  surveyTextToJson,
  useSurveyContext,
} from '../utils';
import { Button } from '@material-ui/core';

interface P {
  item: GetSurveyTemplateItem | null;
  close: (refresh: boolean) => void;
  indent: number | undefined;
  surveyTemplateId: number;
  position: number | undefined;
  languages: string[];
}

const parseTag = (
  values: { tag: string | undefined; text: MultiLanguageString[] | undefined },
  languages: string[]
): string | undefined =>
  !values.tag?.length
    ? getLocalizedValue(stringifyLanguageJson(values.text), languages[0])
    : undefined;

export const EditSurveyItem = (props: P) => {
  const context = useSurveyContext();
  //Tagging
  const [loadedList, setLoadedList] = useState<boolean>(false);
  const [tagList, setTagList] = useState<Array<string>>([]);
  useEffect(() => {
    const getSessionTagList = async () => {
      const data = (await context.client.get(`surveys/items/reportingTags`))
        .data as Array<string>;
      setTagList(data);
    };
    if (!loadedList && context.useTag) {
      setLoadedList(true);
      getSessionTagList();
    }
  }, []);

  const getMatchingList = (values: F) => matchingList(values.tag, tagList);

  type F = Yup.InferType<typeof validation> & { type: SurveyType | '' };
  const validation = useMemo(
    () =>
      Yup.object({
        text: atLeastOneLanguage(),
        additionalText: Yup.array(),
        type: Yup.string().required(context.strings.required),
        answers: Yup.array().of(
          Yup.object({
            answerText: atLeastOneLanguage(),
          })
        ),
        group: Yup.string().required(context.strings.required),
        tag: Yup.string(),
      }).required(context.strings.required),
    []
  );

  const initialValues: F = useMemo(() => {
    const common = {
      type: props.item?.surveyType || '',
      group: props.item?.surveyGroup || 'Both',
      tag: props.item?.reportingTag || '',
      additionalText: parseLanguageJson(props.item?.additionalText),
    } as const;
    if (props.item?.surveyType === 'mc') {
      const parsed = jsonToSurveyText(props.item.surveyText);
      return {
        ...common,
        text: parsed?.text || [],
        answers: (parsed?.answers || []).map((a) => ({ answerText: a })),
      };
    } else {
      return {
        ...common,
        text: parseLanguageJson(props.item?.surveyText),
        answers: [],
      };
    }
  }, [props.item]);

  const getSurveyText = (values: F) => {
    if (values.type === 'mc' && values.answers) {
      const answers = (
        values.answers.map((a) => a.answerText) as MultiLanguageString[][]
      ).filter((a) => a.some((l) => l.value));
      const textJson: ParsedMcSurveyText = {
        text: values.text || [],
        answers: answers,
      };
      return surveyTextToJson(textJson);
    }
    return stringifyLanguageJson(values.text);
  };

  const submit = async (values: F) => {
    if (!values.type) return;
    const data: PostSurveyTemplateItem = {
      surveyText: getSurveyText(values),
      surveyType: values.type,
      indentParent: props.indent,
      surveyTemplateId: props.surveyTemplateId,
      surveyGroup: values.group as Groups,
      position: props.item?.position || props.position,
      reportingTag: parseTag(values, props.languages) || values.tag,
      additionalText: stringifyLanguageJson(values.additionalText),
    };
    if (props.item) {
      await context.client.put(
        `${context.endpoints.postTemplate}/item/${props.item.surveyTemplateItemId}`,
        data
      );
    } else {
      await context.client.post(
        `${context.endpoints.postTemplate}/${props.surveyTemplateId}/item`,
        data
      );
    }
    props.close(true);
  };
  return (
    <EditDialog title="Create new Survey Item" close={() => props.close(false)}>
      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={submit}
      >
        {({ errors, touched, values, setFieldValue }) => {
          const err = {
            text: touched.text ? errors.text : '',
            type: touched.type ? errors.type : '',
            group: touched.group ? errors.group : '',
            additionalText: touched.additionalText ? errors.additionalText : '',
          };
          const inputs = [
            'text',
            ...(values.answers?.map((_, k) => `answers.${k}.answerText`) || []),
            'additionalText',
          ];
          return (
            <LocalizedForm
              initialValues={initialValues}
              autoParse={[]}
              localizeInputs={inputs}
              languages={props.languages}
            >
              <TextField
                name="text"
                label="Survey Text"
                error={err.text}
                type="text"
                key="text"
              />

              {props.item?.surveyType !== 'mc' && (
                <Select name="type" label="Type" error={err.type}>
                  {context.surveyTypes.map((t) => (
                    <option key={t} value={t}>
                      {t[0].toUpperCase() + t.substring(1)}
                    </option>
                  ))}
                </Select>
              )}
              {context.importerLogic && (
                <Select name="group" label="Group" error={err.group}>
                  {allGroups.map((g) => (
                    <option key={g} value={g}>
                      {g}
                    </option>
                  ))}
                </Select>
              )}

              {values.type === 'mc' && (
                <FieldArray name="answers" key={'answers'}>
                  {({ remove, push }) => {
                    return (
                      <>
                        {(values.answers?.length || 0) > 0 &&
                          values.answers?.map((_: unknown, index: number) => {
                            let textError: string | undefined = undefined;
                            if (
                              touched &&
                              errors &&
                              touched.answers &&
                              errors.answers &&
                              touched.answers[index] &&
                              errors.answers[index]
                            ) {
                              const e = errors.answers[index];
                              if (typeof e !== 'string') {
                                // @ts-ignore
                                textError = e.answerText;
                              }
                            }

                            return (
                              <div
                                style={{ paddingBottom: 16 }}
                                key={`answers.${index}`}
                              >
                                <div ref={`answers.${index}.answerText`}>
                                  <TextArea
                                    name={`answers.${index}.answerText`}
                                    label="Answer"
                                    error={textError}
                                    rows={2}
                                    key={`answers.${index}.answerText`}
                                  />
                                </div>

                                <LabelButton
                                  variant="secondary"
                                  onClick={() => remove(index)}
                                >
                                  Delete Answer
                                </LabelButton>
                              </div>
                            );
                          })}

                        <LabelButton
                          variant="secondary"
                          onClick={() => push({ answerText: [] })}
                        >
                          Add Answer
                        </LabelButton>
                      </>
                    );
                  }}
                </FieldArray>
              )}
              <TextField
                name="additionalText"
                label={'Text Shown after'}
                error={err.additionalText}
                key="additionalText"
              />

              {context.useTag && (
                <div>
                  <Field
                    label={'Tag'}
                    name="tag"
                    as={TextField}
                    autoComplete="off"
                    type="text"
                    validationMessage={errors.tag || ''}
                    inputId={'tag'}
                  />
                  <Text variant="copy2">
                    {parseTag(values, props.languages)}
                  </Text>
                  {getMatchingList(values).length > 0 &&
                    getMatchingList(values).map((t) => (
                      <Button
                        onClick={() => {
                          setFieldValue('tag', t);
                        }}
                      >
                        {t}
                      </Button>
                    ))}
                </div>
              )}

              <div>
                <LabelButton variant="primary" type="submit">
                  Submit
                </LabelButton>
              </div>
            </LocalizedForm>
          );
        }}
      </Formik>
    </EditDialog>
  );
};
