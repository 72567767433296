import React from 'react';
import { NotImplemented } from '../../utils/NotImplemented';
import { useIsTheme } from '../../theming';
import { LikeIconActiveAudi } from './LikeIconActiveAudi';
import { LikeIconActiveLight } from './LikeIconActiveLight';
import { LikeIconActiveDark } from './LikeIconActiveDark';

const LikeIconActive = () => {
  const theme = useIsTheme();
  if (theme.audi) {
    return <LikeIconActiveAudi />;
  } else if (theme.neutralLight) {
    return <LikeIconActiveLight />;
  } else if (theme.neutralDark) {
    return <LikeIconActiveDark />;
  } else return <NotImplemented />;
};
export { LikeIconActive };
