import { TextProps } from '@audi/audi-ui-react';
import React, { CSSProperties, FC } from 'react';
import clsx from 'clsx';
import { makeThemedStyles, useThemedStyles } from '../makeThemedStyles';

const styles = makeThemedStyles((theme) => {
  return {
    display1: {
      [theme.breakpoints.only('xs')]: {
        fontSize: 40,
      },
      [theme.breakpoints.only('sm')]: {
        fontSize: 48,
      },
      [theme.breakpoints.only('md')]: {
        fontSize: 60,
      },
      [theme.breakpoints.only('lg')]: {
        fontSize: 72,
      },
      [theme.breakpoints.only('xl')]: {
        fontSize: 84,
      },
    },

    display2: {
      [theme.breakpoints.down('sm')]: {
        fontSize: 36,
      },
      [theme.breakpoints.only('md')]: {
        fontSize: 44,
      },
      [theme.breakpoints.only('lg')]: {
        fontSize: 52,
      },
      [theme.breakpoints.only('xl')]: {
        fontSize: 60,
      },
    },

    order1: {
      [theme.breakpoints.down('sm')]: {
        fontSize: 28,
      },
      [theme.breakpoints.only('md')]: {
        fontSize: 36,
      },
      [theme.breakpoints.only('lg')]: {
        fontSize: 40,
      },
      [theme.breakpoints.only('xl')]: {
        fontSize: 44,
      },
    },

    order2: {
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
      },
      [theme.breakpoints.only('md')]: {
        fontSize: 28,
      },
      [theme.breakpoints.only('lg')]: {
        fontSize: 32,
      },
      [theme.breakpoints.only('xl')]: {
        fontSize: 36,
      },
    },

    order3: {
      [theme.breakpoints.down('md')]: {
        fontSize: 20,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 24,
      },
    },

    order4: {
      [theme.breakpoints.down('md')]: {
        fontSize: 16,
      },
      [theme.breakpoints.only('lg')]: {
        fontSize: 18,
      },
      [theme.breakpoints.only('xl')]: {
        fontSize: 20,
      },
    },

    copy1: {
      fontSize: 16,
    },

    copy2: {
      fontSize: 12,
    },

    copy3: {
      fontSize: 10,
    },

    disabledSec: {
      color: ({ colorScheme }) => colorScheme.buttons.secondaryDisabledText,
    },

    disabledPrim: {
      color: ({ colorScheme }) => colorScheme.buttons.primaryDisabledText,
    },
  };
});

interface AllTextProps extends TextProps {
  primary?: boolean;
  secondary?: boolean;
  disabled?: boolean;
}

export const NeutralButtonText: FC<AllTextProps> = (p) => {
  const cls = useThemedStyles(styles);
  const props = {
    ...p,
    as: p.as || 'p',
    variant: p.variant || 'copy1',
  };
  const style: CSSProperties = {
    fontWeight: props.weight || 'normal',
    fontFamily: "'Outfit', sans-serif",
    margin: 0,
  };

  // console.log('NeutralButtonText', props);
  return (
    <props.as
      style={style}
      className={clsx(
        cls[props.variant],
        props.className,
        props.disabled && props.primary
          ? cls.disabledPrim
          : props.disabled && props.secondary
          ? cls.disabledSec
          : undefined
      )}
    >
      {props.children}
    </props.as>
  );
};
