import { Text as AudiText, TextProps } from '@audi/audi-ui-react';
import React, { FC } from 'react';
import { NotImplemented } from '../../utils/NotImplemented';
import { NeutralText } from './NeutralText';
import { useIsTheme } from '../../theming';

export interface AllTextProps extends TextProps {
  /** Will only used in Dark theme: When we want to particularly specify that color needs to be black (against theme settings) */
  black?: boolean;
}

export const Text: FC<AllTextProps> = (props) => {
  const theme = useIsTheme();
  if (theme.audi) {
    return <AudiText {...props} />;
  } else if (theme.neutral) {
    return <NeutralText {...props} />;
  } else {
    return <NotImplemented />;
  }
};
