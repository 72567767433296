import { useEffect, useState } from 'react';
import { Text, LayoutItem } from '@pp-labs/ui-components';
import { Context, GetSurveyTemplate } from '../Interfaces';
import React from 'react';
import {
  getStringForContext,
  standardFormat,
  useGetLocalizedValue,
  useSurveyContext,
} from '../utils';
import { SurveyTable } from './SurveyTable';
import { LabelButton, tableStyles } from '@pp-labs/ui-components';
import { EditSurvey } from './EditSurvey';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/core';
import DeleteDialogue from '../ConfirmDelete';
import { duplicate } from '../duplicate';

const useStyles = makeStyles({
  ...tableStyles(),
});

type P = {
  /** If a channelId is specified new surveys will be created with that channel
   * and all surveys will be filtered by that channel */
  channelId?: number;
  languages: string[];
  mayHaveTemplates?: boolean;
};

/** Integrate this component into your application for the CMS part. You could build a wrapper for it to apply some styles */
export const SurveyCms = (props: P) => {
  const [surveys, setSurveys] = useState<GetSurveyTemplate[]>([]);
  const context = useSurveyContext();
  const [surveyOpen, setSurveyOpen] =
    useState<{ context: Context; survey?: GetSurveyTemplate } | null>(null);
  const [toDelete, setToDelete] = useState<number | null>(null);

  useEffect(() => {
    refresh();
  }, [props.channelId]);

  const refresh = async () => {
    const newSurveys: GetSurveyTemplate[] = (
      await context.client.get(context.endpoints.getTemplates)
    ).data;

    const channelFiltered = props.channelId
      ? newSurveys.filter((s) => s.channelId === props.channelId)
      : newSurveys;

    const templateSorted = props.mayHaveTemplates
      ? [
          ...channelFiltered.filter((s) => s.market !== null),
          ...channelFiltered.filter((s) => s.market === null),
        ]
      : channelFiltered;

    setSurveys(templateSorted);
  };

  const openSurvey = (c: Context, survey?: GetSurveyTemplate) => {
    setSurveyOpen({ context: c, survey: survey });
  };
  const overallSurveys = surveys.filter((s) =>
    s.surveyContext.startsWith('overall')
  );

  const close = () => setSurveyOpen(null);

  const deleteSurvey = async () => {
    await context.client.delete(
      `${context.endpoints.postTemplate}/${toDelete}`
    );
    await refresh();
    setToDelete(null);
  };

  return (
    <div>
      <div>
        <Text variant="order2" weight="bold">
          General Surveys
        </Text>

        <div>
          {overallSurveys.map((s) => {
            return (
              <SurveyDetails
                survey={s}
                openSurvey={openSurvey}
                setToDelete={setToDelete}
                refresh={refresh}
                key={s.surveyTemplateId}
                mayHaveTemplates={!!props.mayHaveTemplates}
                languages={props.languages}
              />
            );
          })}
        </div>

        <LabelButton
          variant="primary"
          onClick={() => {
            const newContext = `overall-${
              overallSurveys.length + 1
            }` as Context;
            openSurvey(newContext, undefined);
          }}
        >
          Create general Survey
        </LabelButton>
      </div>

      {context.surveyContexts.filter((c) => c !== 'overall').length > 0 && (
        <LayoutItem spaceStackStart="xxxl" spaceStackEnd="l">
          <Text variant="order2" weight="bold">
            Session Surveys
          </Text>
        </LayoutItem>
      )}

      {context.surveyContexts
        .filter((c) => c !== 'overall')
        .map((c) => {
          const survey = surveys.find((s) => s.surveyContext === c);
          const display = getStringForContext(c);
          return (
            <div key={c}>
              <Text variant="order3">{display}</Text>
              {survey ? (
                <SurveyDetails
                  survey={survey}
                  openSurvey={openSurvey}
                  setToDelete={setToDelete}
                  refresh={refresh}
                  mayHaveTemplates={!!props.mayHaveTemplates}
                  languages={props.languages}
                />
              ) : (
                <LabelButton variant="primary" onClick={() => openSurvey(c)}>
                  Create {display}
                </LabelButton>
              )}
            </div>
          );
        })}

      {!!surveyOpen && (
        <EditSurvey
          prevSurvey={surveyOpen?.survey}
          context={surveyOpen?.context}
          refresh={refresh}
          close={close}
          channelId={props.channelId}
          languages={props.languages}
        />
      )}

      <DeleteDialogue
        toBeDeleted={toDelete}
        delete={deleteSurvey}
        reject={() => setToDelete(null)}
      />
    </div>
  );
};

interface SurveyDetailProps {
  survey: GetSurveyTemplate;
  openSurvey: (context: Context, survey: GetSurveyTemplate) => void;
  setToDelete: (id: number) => void;
  refresh: () => Promise<void>;
  mayHaveTemplates: boolean;
  languages: string[];
}
const SurveyDetails = ({
  survey,
  openSurvey,
  setToDelete,
  refresh,
  mayHaveTemplates,
  languages,
}: SurveyDetailProps) => {
  const cls = useStyles();
  const [duplicating, setDuplicating] = useState<boolean>(false);
  const context = useSurveyContext();
  const getLocalized = useGetLocalizedValue();
  const d = () => duplicate(survey, context, setDuplicating, refresh);

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ marginRight: 24 }}>
          <Text variant="order4" weight="bold">
            {getLocalized(survey.title)}
          </Text>
          <Text variant="copy1">
            {survey.startAt &&
              `Start: ${format(survey.startAt * 1000, standardFormat)}`}
          </Text>
          <Text variant="copy1">
            {survey.endAt &&
              `End: ${format(survey.endAt * 1000, standardFormat)}`}
          </Text>
        </div>

        {mayHaveTemplates && survey.market === null ? (
          <div>
            <LabelButton
              variant="secondary"
              className={cls.btnLeft}
              onClick={d}
              disabled={duplicating}
            >
              Create Survey from Template
            </LabelButton>
          </div>
        ) : (
          <div>
            <LabelButton
              variant="secondary"
              className={cls.btnLeft}
              onClick={() => {
                openSurvey(survey.surveyContext, survey);
              }}
            >
              Edit Survey
            </LabelButton>
            <LabelButton
              variant="secondary"
              className={cls.btnRight}
              onClick={() => setToDelete(survey.surveyTemplateId)}
            >
              Delete Survey
            </LabelButton>
          </div>
        )}
      </div>
      <LayoutItem spaceStackEnd="l">
        <SurveyTable
          items={survey.templateItems || []}
          refresh={refresh}
          surveyTemplateId={survey.surveyTemplateId}
          isTemplate={mayHaveTemplates && survey.market === null}
          languages={languages}
        />
      </LayoutItem>
    </div>
  );
};
