import React from 'react';
import { SvgIcon } from '@material-ui/core';

const UnMuteIconNeutral = () => (
  <SvgIcon fontSize="inherit">
    <path
      d="M3 9v6h4l5 5V4L7 9H3zm7-.17v6.34L7.83 13H5v-2h2.83L10 8.83zM16.5 12c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77 0-4.28-2.99-7.86-7-8.77z"
      strokeWidth="1"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export { UnMuteIconNeutral };
