import React from 'react';
import { NotImplemented } from '../../utils/NotImplemented';
import { useIsTheme } from '../../theming';
import { EnterFullScreenNeutral } from './EnterFullScreenNeutral';
import { EnterFullScreenAudi } from './EnterFullScreenAudi';

const EnterFullscreenIcon = () => {
  const theme = useIsTheme();
  if (theme.audi) {
    return <EnterFullScreenAudi />;
  } else if (theme.neutral) {
    return <EnterFullScreenNeutral />;
  } else return <NotImplemented />;
};
/** @deprecated Use EnterFullscreenIconUnused instead */
export const EnterFullScreenWrapper = EnterFullscreenIcon;
export { EnterFullscreenIcon };
