import React, { PropsWithChildren } from 'react';
import { Checkbox as AudiCheckbox } from '@audi/audi-ui-react';
import { FormProps } from './FormProps';
import { ErrorMessage, useFormikContext } from 'formik';
import { NeutralCheckbox } from './NeutralCheckbox';
import { NotImplemented } from '../utils/NotImplemented';
import { useIsTheme } from '../theming';
import { Text } from '../base';

interface CheckboxProps extends FormProps {
  handle: (name: string, checked: boolean) => void;
  value: string;
  currentValue: boolean;
  manual?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = (props) => {
  const theme = useIsTheme();
  const handleInput = () => {
    props.handle(
      props.name,
      props.currentValue ? false : props.value === 'true'
    );
  };
  if (theme.audi) {
    return (
      <AudiCheckbox
        inputId={props.name}
        name={props.name}
        onChange={handleInput}
        checked={props.manual ? props.currentValue : undefined}
      >
        {props.children}
        {!!props.error && (
          <ErrorMessage
            name={props.name}
            component="div"
            className="field-error"
          />
        )}
      </AudiCheckbox>
    );
  } else if (theme.neutral) {
    return <NeutralCheckbox {...props} dark={theme.neutralDark} />;
  } else {
    return <NotImplemented />;
  }
};

type FormikCheckboxProps<Name> = {
  name: Name;
  label: string;
};
export const FormikCheckbox = <Name extends string>(
  props: PropsWithChildren<FormikCheckboxProps<Name>>
) => {
  const { setFieldValue, values, touched, errors } = useFormikContext<
    { [key in Name]: boolean }
  >();
  const error = (touched[props.name] ? errors[props.name] : '') as string;
  return (
    <Checkbox
      currentValue={values[props.name]}
      name={props.name}
      error={error}
      value={'true'}
      handle={setFieldValue}
      label=""
      manual
    >
      {props.children || <Text variant="copy1">{props.label}</Text>}
    </Checkbox>
  );
};

export { CheckboxProps, Checkbox };
