export interface SurveyStrings {
  getLanguage: () => string;
  typeKeyMessage: string;
  rateSession: string;
  rateLot?: string;
  thanksForFeedback: string;
  required: string;
  invalidUnlockCode: string;
  enterUnlockCode: string;
  submitUnlockCode: string;
  takeSurvey: string;
}

export const defaultStrings: SurveyStrings = {
  typeKeyMessage: '',
  rateSession: '',
  rateLot: '',
  thanksForFeedback: '',
  required: '',
  getLanguage: () => 'English',
  invalidUnlockCode: '',
  enterUnlockCode: '',
  submitUnlockCode: '',
  takeSurvey: '',
};
