import React from 'react';
import { NotImplemented } from '../../utils/NotImplemented';
import { useIsTheme } from '../../theming';
import { GroupPeopleIconAudi } from './GroupPeopleIconAudi';
import { GroupPeopleIconNeutral } from './GroupPeopleIconNeutral';

const GroupPeopleIcon = () => {
  const theme = useIsTheme();
  if (theme.audi) {
    return <GroupPeopleIconAudi />;
  } else if (theme.neutral) {
    return <GroupPeopleIconNeutral />;
  } else return <NotImplemented />;
};

export { GroupPeopleIcon };
