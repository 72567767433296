import { makeStyles } from '@material-ui/core/styles';
import { LayoutItem, Text } from '@audi/audi-ui-react';
import { useEffect, useState } from 'react';
import { CodeProtection } from './CodeProtection';
import {
  ImageList,
  ImageListItem,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { AdaptedIcon, LabelButton } from '@pp-labs/ui-components';
import {
  isMainSurvey,
  useGetLocalizedValue,
  useSurveyContext,
  useSurveyTemplates,
} from '../utils';
import { GetSurveyTemplate, GetUserSurvey } from '../Interfaces';
import { GeneralSurvey } from '../GeneralSurvey';
import React from 'react';

const useStyles = makeStyles({
  imageWrapper: {
    width: '100%',
    paddingTop: '56.25%',
    position: 'relative',
  },
  innerImage: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    position: 'absolute',
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  iconWrapper: {
    width: '100%',
    height: '100%',
    top: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'right',
  },
  innerIconWrapper: {
    fontSize: 32,
    paddingRight: 12,
  },
  tile: {
    width: '25%',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
  },
});

export const SurveyList = (props: {
  channelId?: number;
  surveyId?: number;
}) => {
  const getLocalized = useGetLocalizedValue();
  const context = useSurveyContext();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const cls = useStyles();
  const [takenSurveys, setTakenSurveys] =
    useState<GetUserSurvey[] | null>(null);
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const surveys = useSurveyTemplates(true, true).filter(
    (s) =>
      !isMainSurvey(s) && (!props.channelId || props.channelId === s.channelId)
  );
  useEffect(() => {
    if (!props.surveyId || !takenSurveys || initialLoad) return;
    const survey = surveys.find((s) => s.surveyTemplateId === props.surveyId);
    if (survey) {
      handleClick(survey);
      setInitialLoad(true);
    }
  }, [takenSurveys, surveys]);

  const fetch = async () => {
    const takenSurveys = await context.client.get<GetUserSurvey[]>(
      context.endpoints.getSurvey
    );
    setTakenSurveys(takenSurveys.data);
  };
  useEffect(() => {
    fetch();
  }, []);
  const [selectedSurvey, setSelectedSurvey] =
    useState<{
      survey: GetSurveyTemplate;
      alreadyDone: boolean;
    } | null>(null);

  const handleClick = (survey: GetSurveyTemplate) => {
    const alreadyDone = !!takenSurveys?.some(
      (d) => d.surveyTemplateId === survey.surveyTemplateId
    );
    setSelectedSurvey({ survey: survey, alreadyDone: alreadyDone });
  };

  return (
    <div>
      <ImageList cols={xs ? 1 : sm ? 2 : 4} rowHeight="auto">
        {surveys.map((s) => {
          const alreadyDone = takenSurveys?.some(
            (d) => d.surveyTemplateId === s.surveyTemplateId
          );
          return (
            <ImageListItem
              onClick={() => handleClick(s)}
              style={{ cursor: alreadyDone ? undefined : 'pointer' }}
            >
              <div className={cls.imageWrapper}>
                <div
                  className={cls.innerImage}
                  style={{
                    backgroundImage: s.thumbnail
                      ? `url(${s.thumbnail})`
                      : undefined,
                  }}
                />
                {alreadyDone && (
                  <div className={cls.iconWrapper}>
                    <div className={cls.innerIconWrapper}>
                      <AdaptedIcon />
                    </div>
                  </div>
                )}
              </div>
              <LayoutItem spaceStackStart="m">
                <Text variant="order4" weight="bold">
                  {getLocalized(s.title)}
                </Text>
              </LayoutItem>
              <div>
                <LabelButton variant="secondary" onClick={() => handleClick(s)}>
                  {context.strings.takeSurvey}
                </LabelButton>
              </div>
            </ImageListItem>
          );
        })}
      </ImageList>
      {selectedSurvey && (
        <CodeProtection
          type="survey"
          close={() => setSelectedSurvey(null)}
          verify={selectedSurvey.survey}
          bypass={
            selectedSurvey.alreadyDone ||
            !selectedSurvey.survey.requiresSurveyCode
          }
        >
          <GeneralSurvey
            accessor={(s) => s}
            context={selectedSurvey.survey.surveyContext}
            templateId={selectedSurvey.survey.surveyTemplateId}
            id={0}
            live={false}
            onFinish={fetch}
          />
        </CodeProtection>
      )}
    </div>
  );
};
