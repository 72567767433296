import React from 'react';
import { SvgIcon } from '@material-ui/core';

const PollClearIconDark = () => (
  <SvgIcon fontSize="inherit" style={{ color: 'white' }}>
    <path
      d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
      strokeWidth="1"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export { PollClearIconDark };
