import React from 'react';
import { SvgIcon } from '@material-ui/core';

const UnadaptedIcon = () => (
  <SvgIcon fontSize="inherit">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Desktop-HD" transform="translate(-817.000000, -174.000000)">
        <g id="Group" transform="translate(817.000000, 174.000000)">
          <circle id="Oval" fill="#F50537" cx="12" cy="12" r="12" />
          <g
            id="cancel-small"
            stroke="#FFFFFF"
            transform="translate(6.000000, 6.000000)"
          >
            <path
              d="M0.832615385,11.1692308 L11.1673846,0.830769231 M0.830769231,0.832615385 L11.1692308,11.1673846"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export { UnadaptedIcon };
