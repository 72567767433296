import Bowser, { Parser } from 'bowser';

type Browser = Parser.Parser;
const getBrowser = (): Browser => Bowser.getParser(window.navigator.userAgent);

const isSafari = (browser?: Browser) =>
  (browser || getBrowser()).satisfies({
    safari: '>=1',
  });

const isIe = (browser?: Browser) =>
  (browser || getBrowser()).satisfies({
    ie: '>=1',
  });

const isNotDesktop = (browser?: Browser) =>
  (browser || getBrowser()).parsePlatform().type !== 'desktop';

const isChromeOs = (browser?: Browser) =>
  (browser || getBrowser()).parseOS().name === 'Chrome OS';

const isMobileSafari = () => {
  const browser = getBrowser();
  return isNotDesktop(browser) && isSafari(browser);
};

export {
  Browser,
  getBrowser,
  isSafari,
  isIe,
  isNotDesktop,
  isChromeOs,
  isMobileSafari,
};
