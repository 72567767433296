import React from 'react';
import { SvgIcon } from '@material-ui/core';

const PrivateIconAudi = () => (
  <SvgIcon fontSize="inherit" viewBox="0 0 24 24">
    <path
      d="M13,11a2.946,2.946,0,0,0,3-3V0H0V8a2.946,2.946,0,0,0,3,3Z"
      transform="translate(4 9.5)"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
      strokeWidth="1"
    />
    <path
      d="M0,6V3A2.946,2.946,0,0,1,3,0H7a2.946,2.946,0,0,1,3,3V6Z"
      transform="translate(7 3.5)"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
      strokeWidth="1"
    />
    <rect width="24" height="24" fill="none" />
    <line
      y2="4"
      transform="translate(12 13)"
      fill="none"
      stroke="#000"
      strokeWidth="1"
    />
  </SvgIcon>
);

export { PrivateIconAudi };
