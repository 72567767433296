import React from 'react';
import { SvgIcon } from '@material-ui/core';

const LockIconAudi = () => (
  <SvgIcon viewBox="0 0 40 46">
    <path
      d="M22.96875,37.5 L22.96875,32.2 C21.5395833,31.5 20.6208333,29.9 21.0291667,28.2 C21.3354167,26.9 22.35625,25.9 23.6833333,25.6 C26.03125,25.1 28.0729167,26.8 28.0729167,29 C28.0729167,30.4 27.0520833,31.6 26.03125,32.2 L26.03125,37.5 L22.96875,37.5 Z M9.69791667,20.5 L9.69791667,16.5 C9.69791667,9.32029825 15.256145,3.5 22.1125672,3.5 L26.8874328,3.5 C33.743855,3.5 39.3020833,9.32029825 39.3020833,16.5 L39.3020833,20.5 M6.63541667,20.5 L42.3645833,20.5 L42.3645833,43.5 L6.63541667,43.5 L6.63541667,20.5 Z"
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export { LockIconAudi };
