import { ButtonProps } from '@audi/audi-ui-react';
import React, { CSSProperties, FC } from 'react';
import clsx from 'clsx';
import { makeThemedStyles, useThemedStyles } from '../makeThemedStyles';
import { NeutralButtonText } from './NeutralButtonText';

const styles = makeThemedStyles({
  primary: {
    background: ({ colorScheme }) => colorScheme.buttons.primaryButton,
    color: '#FFFFFF',
    border: '0px',
    '&:hover': {
      background: ({ colorScheme }) => colorScheme.buttons.primaryHover,
    },
    '&:disabled': {
      background: ({ colorScheme }) => colorScheme.buttons.primaryDisabled,
      color: ({ colorScheme }) => colorScheme.buttons.primaryDisabledText,
      border: ({ colorScheme }) =>
        `2px solid #${colorScheme.buttons.primaryDisabledText}`,
    },
  },
  secondary: {
    background: ({ colorScheme }) => colorScheme.buttons.secondaryButton,
    color: '#797979',
    border: ({ isTheme }) =>
      isTheme.neutralLight ? '2px solid #D8D8D8' : '2px solid #464646',
    '&:hover': {
      border: ({ isTheme }) =>
        isTheme.neutralLight ? '2px solid #000000' : '2px solid #ffffff',
      color: '#ffffff',
      background: ({ colorScheme }) => colorScheme.buttons.secondaryHover,
    },
    '&:disabled': {
      background: ({ colorScheme }) => colorScheme.buttons.secondaryDisabled,
      color: ({ colorScheme }) => colorScheme.buttons.secondaryDisabledText,
      border: ({ colorScheme }) =>
        `2px solid #${colorScheme.buttons.secondaryDisabledText}`,
    },
  },
  text: {
    cursor: 'pointer',
    color: '#797979 ',
    '&:hover': {
      color: ({ isTheme }) => (isTheme.neutralLight ? '#000000' : '#ffffff'),
    },
  },
});

interface LabelButtonProps extends ButtonProps {
  className?: string;
  dark?: boolean;
  softDisabled?: boolean;
}
export const NeutralLabelButton: FC<LabelButtonProps> = (p) => {
  const cls = useThemedStyles(styles);
  const props = {
    ...p,
  };
  const style: CSSProperties = {
    padding: '18px 42px',
    cursor: props.disabled || props.softDisabled ? 'not-allowed' : 'pointer',
    display: 'inline-grid',
  };

  if (props.variant === 'text') {
    return (
      <div onClick={props.onClick}>
        <NeutralButtonText weight="bold" className={cls.text}>
          {props.children}
        </NeutralButtonText>
      </div>
    );
  } else {
    return (
      <button
        style={style}
        className={clsx(cls[props.variant], props.className)}
        onClick={props.onClick}
        // default to type button, otherwise it would default to submit, which we certainly not want.
        // AudiLabelButton has a similar builtin mechanism against it.
        type={props.type || 'button'}
        disabled={props.disabled || props.softDisabled}
      >
        <NeutralButtonText
          weight="bold"
          primary={props.variant === 'primary'}
          secondary={props.variant === 'secondary'}
          disabled={props.disabled || props.softDisabled}
        >
          {props.children}
        </NeutralButtonText>
      </button>
    );
  }
};
