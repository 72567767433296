import React from 'react';
import { NotImplemented } from '../../utils/NotImplemented';
import { useIsTheme } from '../../theming';
import { FavoriteIconAudi } from './FavoriteIconAudi';
import { FavoriteIconDark } from './FavoriteIconDark';
import { FavoriteIconLight } from './FavoriteIconLight';

export interface FavoriteIconProps {
  filled?: boolean;
  fontSize: 'small' | 'inherit' | 'default' | 'large' | undefined;
}

const FavoriteIcon = (props: FavoriteIconProps) => {
  const theme = useIsTheme();
  if (theme.audi) {
    return <FavoriteIconAudi {...props} />;
  } else if (theme.neutralDark) {
    return <FavoriteIconDark {...props} />;
  } else if (theme.neutralLight) {
    return <FavoriteIconLight {...props} />;
  } else return <NotImplemented />;
};

export { FavoriteIcon };
