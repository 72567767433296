import React from 'react';
import { makeStyles, SvgIcon } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  svgWrapper: {
    fontSize: '30px',
    color: '#767676',
    cursor: 'pointer',
    '&:hover': {
      color: '#000000',
    },
  },
}));
const ThumbUpIconOutlineLight = () => {
  const cls = useStyles();
  return (
    <SvgIcon className={cls.svgWrapper}>
      <path d="M9 21h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.58 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2zM9 9l4.34-4.34L12 10h9v2l-3 7H9V9zM1 9h4v12H1z" />
    </SvgIcon>
  );
};

export { ThumbUpIconOutlineLight };
