import React from 'react';
import { NotImplemented } from '../../utils/NotImplemented';
import { useIsTheme } from '../../theming';
import { DownloadIconNeutral } from './DownlaodIconNeutral';
import { DownloadIconAudi } from './DownloadIconAudi';

const DownloadIcon = () => {
  const theme = useIsTheme();
  if (theme.audi) {
    return <DownloadIconAudi />;
  } else if (theme.neutral) {
    return <DownloadIconNeutral />;
  } else return <NotImplemented />;
};
/** @deprecated Use DownloadIcon instead */
export const DownloadIconWrapper = DownloadIcon;
export { DownloadIcon };
