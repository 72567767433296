import React from 'react';
import { NotImplemented } from '../../utils/NotImplemented';
import { useIsTheme } from '../../theming';
import { DisLikeIconInactiveAudi } from './DisLikeIconInactiveAudi';
import { DisLikeIconInactiveLight } from './DisLikeIconInactiveLight';
import { DisLikeIconInactiveDark } from './DisLikeIconInactiveDark';

const DisLikeIconInactive = () => {
  const theme = useIsTheme();
  if (theme.audi) {
    return <DisLikeIconInactiveAudi />;
  } else if (theme.neutralLight) {
    return <DisLikeIconInactiveLight />;
  } else if (theme.neutralDark) {
    return <DisLikeIconInactiveDark />;
  } else return <NotImplemented />;
};
export { DisLikeIconInactive };
