import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { UploadIcon } from '../icons';
import { IconButton } from '@material-ui/core';
import { Text } from '@audi/audi-ui-react';
import { FileTypes } from './FormProps';
import { DownloadButton } from './DownloadButton';
import clsx from 'clsx';
import { useFormikContext } from 'formik';

const useStyles = makeStyles({
  mainWrapper: {
    width: '200px',
    lineBreak: 'anywhere',
    overflow: 'hidden',
  },
  imageWrapper: {
    background: '#666666',
    height: '128px',
  },
  wrapper: {
    height: '128px',
    border: 'solid 1px #5E5E5E',
  },
  iconWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    flexDirection: 'column',
    cursor: 'pointer',
    backgroundSize: 'cover',
  },
  white: {
    color: 'white',
  },
  error: {
    color: '#BB0A30',
  },
});

interface P {
  type: FileTypes;
  noText?: boolean;
  prevUrl?: string;
  prevName?: string;
  variant: 'dark' | 'light';
  name: string;
  label: string;
}

const BigUploadButton = (props: P) => {
  const cls = useStyles();
  const hiddenFileInput = React.useRef(null);
  const { setFieldValue, errors, touched } = useFormikContext();
  const handleClick = () => {
    // @ts-ignore
    hiddenFileInput.current.click();
  };

  const handleChange = (event: any) => {
    const fileUploaded = event.target.files[0];
    setFieldValue(props.name, fileUploaded);
  };
  const getAccept = (): string => {
    switch (props.type) {
      case 'image':
        return 'image/png,image/jpeg';
      case 'pdf':
        return 'application/pdf';
      case 'mixed':
        return 'image/png,image/jpeg,application/pdf';
      case 'vtt':
        return '.vtt';
      case 'video':
        return 'video/mp4';
      case 'csv':
        return '.csv';
    }
  };
  const error = touched[props.name] ? errors[props.name] : '';
  return (
    <div className={cls.mainWrapper}>
      <div
        className={clsx(
          props.variant === 'dark' ? cls.imageWrapper : cls.wrapper
        )}
      >
        <div
          className={clsx(cls.iconWrapper)}
          onClick={handleClick}
          style={{
            backgroundImage: props.prevUrl
              ? `linear-gradient(to top, rgba(0,0,0,.5), rgba(0,0,0,.5)),url(${props.prevUrl})`
              : '',
          }}
        >
          <IconButton disabled>
            <UploadIcon
              video={props.variant === 'light'}
              image={props.variant === 'dark'}
            />
          </IconButton>
          <Text
            className={clsx(props.variant === 'dark' && cls.white)}
            variant={'copy1'}
          >
            {props.label}
          </Text>
        </div>
        <input
          type="file"
          accept={getAccept()}
          ref={hiddenFileInput}
          onChange={handleChange}
          onClick={(event) => {
            event.currentTarget.value = ''; // Allows handleChange to be called when selecting the same file again
            handleChange(event);
          }}
          style={{ display: 'none' }}
        />
      </div>
      {props.prevUrl && (
        <div>
          <DownloadButton
            name={props.prevName || 'current_thumbnail'}
            url={props.prevUrl || ''}
          />
        </div>
      )}
      {error && (
        <Text className={cls.error} variant={'copy2'}>
          {error}
        </Text>
      )}
    </div>
  );
};
export { BigUploadButton };
