import React from 'react';
import { IconButton } from '@material-ui/core';
import { isMobileSafari } from '../helpers';
import { DownloadIcon } from '../icons';
import { Text } from '../base/Text/Text';

interface DownloadButtonProps {
  name: string;
  url: string;
}

const DownloadButton = (props: DownloadButtonProps) => {
  const download = async () => {
    const a = document.createElement('a');
    a.href = props.url;
    a.download = props.name;
    if (!isMobileSafari()) a.target = '_blank';
    a.click();
  };

  return (
    <div>
      <IconButton onClick={download}>
        <DownloadIcon />
      </IconButton>
      <Text as="span" variant="copy1">
        {props.name}
      </Text>
    </div>
  );
};

export { DownloadButton, DownloadButtonProps };
