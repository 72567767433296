import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Button, ButtonProps } from '@audi/audi-ui-react';
import { NotImplemented } from '../NotImplemented';
import { NeutralLabelButton } from './NeutralLabelButton';
import { useIsTheme } from '../../theming';

const useStyles = makeStyles(() => ({
  button: {
    marginTop: '12px',
    marginBottom: '12px',
    display: 'inline-flex',
  },
}));

interface LabelButtonProps extends ButtonProps {
  className?: string;
  lotWhiteOutline?: boolean;
}

const LabelButton: React.FC<LabelButtonProps> = (props) => {
  const cls = useStyles();
  const theme = useIsTheme();
  const cn = props.className ? `${cls.button} ${props.className}` : cls.button;

  if (theme.audi || props.lotWhiteOutline) {
    return (
      <Button {...props} className={cn}>
        {props.children}
      </Button>
    );
  } else if (theme.neutral) {
    return (
      <NeutralLabelButton {...props} className={cn} dark={theme.neutralDark}>
        {props.children}
      </NeutralLabelButton>
    );
  } else {
    return <NotImplemented />;
  }
};

export { LabelButton, LabelButtonProps };
