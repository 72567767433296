import React, { useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { Text } from '@pp-labs/ui-components';
import { Select } from '@pp-labs/ui-components';
import { useFormikContext } from 'formik';
export const appendZero = (number: number): string => {
  if (number < 10) {
    return `0${number}`;
  }
  return `${number}`;
};

export const getMonth = (n: number) => {
  // by purpose defined here in case we later use strings
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return months[n];
};

interface EmitterProps {
  name: string;
  /** Use to write Date to State and not Formik */
  setValue?: (date: Date) => void;
  initialDate?: Date;
}

interface ValueProps {
  year: string;
  month: string;
  day: string;
  hour: string;
  minute: string;
  [key: string]: string;
}

const getDate = (values: ValueProps) =>
  new Date(
    Number(values.year),
    Number(values.month),
    Number(values.day),
    Number(values.hour),
    Number(values.minute)
  );

export const DateSelector = (props: EmitterProps) => {
  const { values, setFieldValue, errors, touched } =
    useFormikContext<ValueProps>();
  const { setValue, name } = props;

  const initialValues = useMemo(() => {
    const date = props.initialDate || new Date();
    return {
      year: date.getFullYear().toString(),
      month: date.getMonth().toString(),
      day: date.getDate().toString(),
      hour: date.getHours().toString(),
      minute: date.getMinutes().toString(),
    };
  }, [props.initialDate]);
  useEffect(() => {
    const d = getDate(values);
    if (setValue) {
      setValue(d);
    } else {
      const date = d.getTime().toString();
      if (values[name] === date) return;
      setFieldValue(name, date);
    }
  }, [values, name, setValue, setFieldValue]);
  const et = {
    year: touched.year ? errors.year : '',
    month: touched.month ? errors.month : '',
    day: touched.day ? errors.day : '',
    hour: touched.hour ? errors.hour : '',
    minute: touched.minute ? errors.minute : '',
  };

  return <DatePicker et={et} initialValues={initialValues} prefix={name} />;
};

const useStyles = makeStyles(() => ({
  timeCodeContainer: {
    display: 'inline-block',
    verticalAlign: 'top',
    width: '150px',
    padding: '0px 12px',
  },
}));

interface P {
  et: any;
  initialValues: any;
  generic?: boolean;
  suffix?: string;
  prefix?: string;
}

/**
 * A crappy DatePicker
 */

export const DatePicker = (props: P) => {
  const cls = useStyles();
  const suf = props.suffix || '';
  const pre = props.prefix || '';
  const year = `${pre}year${suf}`;
  const month = `${pre}month${suf}`;
  const day = `${pre}day${suf}`;
  const hour = `${pre}hour${suf}`;
  const minute = `${pre}minute${suf}`;
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div>
        {props.generic && (
          <>
            <div className={cls.timeCodeContainer}>
              <Select name={year} label={'Year'} error={props.et[year]}>
                {new Array(2).fill(0).map((_, index) => {
                  const date = index + currentYear;
                  return (
                    <option key={`year-${date}`} value={date}>
                      {date}
                    </option>
                  );
                })}
              </Select>
            </div>
            <div className={cls.timeCodeContainer}>
              <Select name={month} label={'Month'} error={props.et[month]}>
                {new Array(12).fill(0).map((_, index) => {
                  const date = index;
                  return (
                    <option key={`month-${date}`} value={date}>
                      {getMonth(date)}
                    </option>
                  );
                })}
              </Select>
            </div>
          </>
        )}
        <div className={cls.timeCodeContainer}>
          <Select name={day} label={'Day'} error={props.et[day]}>
            {new Array(props.generic ? 31 : 29).fill(0).map((_, index) => {
              const date = index + (props.generic ? 1 : 2);
              // eslint-disable-next-line max-len
              return (
                <option key={`date-${date}`} value={date}>
                  {props.generic ? date : `${appendZero(date)}.12.2020`}
                </option>
              );
            })}
          </Select>
        </div>
        <div className={cls.timeCodeContainer}>
          <Select name={hour} label={'Hour'} error={props.et[hour]}>
            {new Array(24).fill(0).map((_, index) => {
              return (
                <option key={`hour-${index}`} value={index}>
                  {index}
                </option>
              );
            })}
          </Select>
        </div>
        <div className={cls.timeCodeContainer}>
          <Select name={minute} label={'Minute'} error={props.et[minute]}>
            {new Array(4).fill(0).map((_, index) => {
              const v = index * 15;
              return (
                <option key={`minute-${v}`} value={v}>
                  {appendZero(v)}
                </option>
              );
              // return (<option key={`minute-${index}`} value={index}>{index}</option>);
            })}
          </Select>
        </div>
        <div className={cls.timeCodeContainer}>
          <Text as="span" variant="copy1">
            Timezone:
            <br /> {getCurrentTimezone()}
          </Text>
        </div>
      </div>
    </>
  );
};

export const getCurrentTimezone = () => {
  const offset = new Date().getTimezoneOffset() / 60;
  if (offset > 0) return `UTC-${offset}`;
  return `UTC+${-offset}`;
};
