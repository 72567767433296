import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const PauseIconAudi = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" viewBox="-1 -1 25 24" {...props}>
    <path
      d="M9.5,7 L9.5,16 M13.5,7 L13.5,16 M11.5,22.5 C17.5751322,22.5 22.5,17.5751322 22.5,11.5 C22.5,5.42486775 17.5751322,0.5 11.5,0.5 C5.42486775,0.5 0.5,5.42486775 0.5,11.5 C0.5,17.5751322 5.42486775,22.5 11.5,22.5 Z"
      stroke="currentColor"
      strokeWidth="0.5"
      fill="none"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export { PauseIconAudi };
