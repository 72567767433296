import { Dialog, DialogProps as AudiDialogProps } from '@material-ui/core';
import React from 'react';

/**
 * Wrapper for MUI Dialog, ensures that the Fonts are loaded correctly in Dialogues
 */

const AudiDialog = (props: AudiDialogProps) => (
  <Dialog
    {...props}
    container={() => document.getElementsByClassName('MuiContainer-root')[0]}
  >
    {props.children}
  </Dialog>
);

export { AudiDialog, AudiDialogProps };
