import React from 'react';
import { makeStyles, SvgIcon } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  svgWrapper: {
    fontSize: '36px',
    color: '#7C7C7C',
    cursor: 'pointer',
    '&:hover': {
      color: '#000000',
    },
  },
}));
const NotificationIconLight = () => {
  const cls = useStyles();
  return (
    <SvgIcon className={cls.svgWrapper}>
      <path
        d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z"
        stroke="currentColor"
        strokeWidth="0"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export { NotificationIconLight };
