import React from 'react';
import { SvgIcon } from '@material-ui/core';

const AdaptedIcon = () => (
  <SvgIcon fontSize="inherit">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="system-select-small" fill="#009900">
        <path
          d="M11.5,22.5 C17.5751322,22.5 22.5,17.5751322 22.5,11.5 C22.5,5.42486775 17.5751322,0.5 11.5,0.5 C5.42486775,0.5 0.5,5.42486775 0.5,11.5 C0.5,17.5751322 5.42486775,22.5 11.5,22.5 Z"
          id="Path"
        />
        <polyline
          id="Path"
          stroke="#FFFFFF"
          points="7.3 11.3 10.6 14.6 15.7 7.4"
        />
      </g>
    </g>
  </SvgIcon>
);

export { AdaptedIcon };
