import React from 'react';
import { DialogActions, DialogTitle } from '@material-ui/core';
import { Text } from '@pp-labs/ui-components';
import { LabelButton, AudiDialog } from '@pp-labs/ui-components';

interface P {
  toBeDeleted: number | null;
  delete: () => void;
  reject: () => void;
}

/**
 * Dialogue to confirm deletions
 */

const DeleteDialogue = (props: P) => {
  const open = props.toBeDeleted !== null;
  return (
    <AudiDialog
      open={open}
      onClose={props.reject}
      fullWidth={false}
      maxWidth={'lg'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Text as="p" variant="order4">
          {'Are you sure you want to delete?'}
        </Text>
      </DialogTitle>
      <DialogActions>
        <LabelButton onClick={props.reject} variant="secondary">
          No
        </LabelButton>
        <LabelButton onClick={props.delete} variant="secondary">
          Yes
        </LabelButton>
      </DialogActions>
    </AudiDialog>
  );
};
export default DeleteDialogue;
