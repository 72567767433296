import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles({
  svgWrapper: {
    fontSize: '44px',
    color: 'black',
  },
});
const DocumentIcon = () => {
  const cls = useStyle();
  return (
    <SvgIcon fontSize="inherit" className={cls.svgWrapper}>
      <path
        d="M7,18.5 L17,18.5 M7,15.5 L17,15.5 M7,12.5 L17,12.5 M14.5,2.5 L14.5,7.5 L19.5,7.5 M19.5,7.5 L19.5,21.5 L4.5,21.5 L4.5,2.5 L14.5,2.5 L19.5,7.5 Z"
        stroke="currentColor"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export { DocumentIcon };
