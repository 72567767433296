import React from 'react';
import { SvgIcon } from '@material-ui/core';

const PollClearIconAudi = () => {
  return (
    <SvgIcon style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
      <g
        id="Desktop-Layouts"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="dCLX_mytraining_LOT-(Trainerview)"
          transform="translate(-494.000000, -651.000000)"
          stroke="black"
        >
          <g id="Videobar_trainer" transform="translate(51.000000, 627.000000)">
            <g
              id="system-cancel-small"
              transform="translate(443.000000, 24.017444)"
            >
              <path
                d="M15.3693913,8.52104348 L8.55443478,15.3370435 M15.384,15.336 L8.57217391,8.52417391"
                id="Shape"
              ></path>
              <circle id="Oval" cx="12" cy="12" r="11.4782609"></circle>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export { PollClearIconAudi };
