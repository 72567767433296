import React from 'react';
import { SvgIcon } from '@material-ui/core';

const MyScheduleIconAudi = () => {
  return (
    <SvgIcon fontSize="inherit">
      <rect
        id="Rechteck_5"
        data-name="Rechteck 5"
        width="24"
        height="24"
        fill="none"
      />
      <rect
        id="Rechteck_70"
        data-name="Rechteck 70"
        width="14"
        height="20.519"
        transform="translate(1.481 22.542) rotate(-90)"
        fill="none"
        stroke="#000"
        strokeWidth="1"
      />
      <path
        id="Pfad_106"
        data-name="Pfad 106"
        d="M0,3.968V0"
        transform="translate(8 1.097)"
        fill="none"
        stroke="#000"
        strokeWidth="1"
      />
      <path
        id="Pfad_107"
        data-name="Pfad 107"
        d="M0,5.542V0"
        transform="translate(1.481 3)"
        fill="none"
        stroke="#000"
        strokeLinecap="square"
        strokeWidth="1"
      />
      <line
        id="Linie_11"
        data-name="Linie 11"
        y1="4"
        transform="translate(15 1)"
        fill="none"
        stroke="#000"
        strokeWidth="1"
      />
      <line
        id="Linie_12"
        data-name="Linie 12"
        y1="6"
        transform="translate(22 3)"
        fill="none"
        stroke="#000"
        strokeLinecap="square"
        strokeWidth="1"
      />
      <line
        id="Linie_13"
        data-name="Linie 13"
        x1="5"
        transform="translate(17 3)"
        fill="none"
        stroke="#000"
        strokeLinecap="square"
        strokeWidth="1"
      />
      <line
        id="Linie_14"
        data-name="Linie 14"
        x1="4"
        transform="translate(2 3)"
        fill="none"
        stroke="#000"
        strokeLinecap="square"
        strokeWidth="1"
      />
      <line
        id="Linie_15"
        data-name="Linie 15"
        x1="3"
        transform="translate(10 3)"
        fill="none"
        stroke="#000"
        strokeLinecap="square"
        strokeWidth="1"
      />
    </SvgIcon>
  );
};

export { MyScheduleIconAudi };
