import React from 'react';
import { makeStyles, SvgIcon } from '@material-ui/core';

const useStyle = makeStyles(() => ({
  icon: {
    margin: '2px 8px 2px -4px',
    'pointer-events': 'all',
    padding: '0',
  },
}));

const DisLikeIconInactiveLight = () => {
  const cls = useStyle();
  return (
    <SvgIcon
      className={cls.icon}
      style={{ width: 48, height: 48 }}
      viewBox="0 -1 48 48"
    >
      <g
        id="Desktop-Layouts"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="LOT_player_icons_new"
          transform="translate(-197.000000, -98.000000)"
        >
          <g id="thumbs-up" transform="translate(197.000000, 98.000000)">
            <circle
              id="Oval-Copy-14"
              fill="#FFFFFF"
              cx="24.5"
              cy="22.5"
              r="22.5"
              stroke="black"
              strokeWidth="1"
              fillRule="evenodd"
            ></circle>
            <g
              transform="translate(13.000000, 11.000000)"
              fill="#000000"
              id="Fill-1"
            >
              <path d="M15 3H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v2c0 1.1.9 2 2 2h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L9.83 23l6.59-6.59c.36-.36.58-.86.58-1.41V5c0-1.1-.9-2-2-2zm0 12l-4.34 4.34L12 14H3v-2l3-7h9v10zm4-12h4v12h-4z"></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export { DisLikeIconInactiveLight };
