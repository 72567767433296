import React from 'react';
import { NotImplemented } from '../../utils/NotImplemented';
import { useIsTheme } from '../../theming';
import { UploadIconDark } from './SmallUploadIconDark';
import { UploadIconAudi } from './SmallUploadIconAudi';

const SmallUploadIcon = () => {
  const theme = useIsTheme();
  if (theme.neutralDark) {
    return <UploadIconDark />;
  } else if (!theme.neutralDark) {
    return <UploadIconAudi />;
  } else return <NotImplemented />;
};

export { SmallUploadIcon };
