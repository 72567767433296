import React, { FC } from 'react';
// Import can't be shortened as it causes a circular dependency
import { makeThemedStyles, useThemedStyles } from '../utils/makeThemedStyles';

const styles = makeThemedStyles({
  background: {
    minHeight: '100vh',
    position: 'relative',
    background: ({ colorScheme }) => colorScheme.background,
  },
});

export const PageWrapper: FC = (props) => {
  const cls = useThemedStyles(styles);
  return <div className={cls.background}>{props.children}</div>;
};
