import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import { CookieCategory } from './CookieDialog';
import { Text } from '@audi/audi-ui-react';
import { Spacer } from './Spacer/Spacer';
import { tableStyles } from '../tables/tableStyles';

interface CookieInformationsProps {
  header?: any;
  cookies: CookieCategory[];
  strings?: any;
}
const useStyles = makeStyles(() => ({
  ...tableStyles(),
}));
/**
 * Cookie information page
 */

const CookieInformations = (props: CookieInformationsProps) => {
  const cls = useStyles();
  return (
    <div>
      <div>{props.header && props.header}</div>

      <Spacer spaceStackStart="xxl" />
      <Text as="h3" variant="order1">
        {props.strings.categories ? props.strings.categories : 'Categories'}
      </Text>
      <TableContainer>
        <Table aria-label="simple table" className={cls.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Text as="h3" variant="order3">
                  {props.strings.categories
                    ? props.strings.categories
                    : 'Categories'}
                </Text>
              </TableCell>
              <TableCell>
                <Text as="h3" variant="order3">
                  {props.strings.description
                    ? props.strings.description
                    : 'Description'}
                </Text>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.cookies.map((cc, k) => {
              return (
                <TableRow key={k}>
                  <TableCell>
                    <Text as="p" variant="copy1">
                      {cc.title}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text as="p" variant="copy1">
                      {cc.description}
                    </Text>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Spacer spaceStackStart="xxl" />
      <Text as="h3" variant="order1">
        {props.strings.cookies ? props.strings.cookies : 'Cookies'}
      </Text>
      <TableContainer>
        <Table aria-label="simple table" className={cls.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Text as="h3" variant="order3">
                  {props.strings.cookieName
                    ? props.strings.cookieName
                    : 'Cookie Name'}
                </Text>
              </TableCell>
              <TableCell>
                <Text as="h3" variant="order3">
                  {props.strings.description
                    ? props.strings.description
                    : 'Description'}
                </Text>
              </TableCell>
              <TableCell>
                <Text as="h3" variant="order3">
                  {props.strings.category ? props.strings.category : 'Category'}
                </Text>
              </TableCell>

              <TableCell>
                <Text as="h3" variant="copy1">
                  {props.strings.expirationDate
                    ? props.strings.expirationDate
                    : 'Expiration Date'}
                </Text>
              </TableCell>

              <TableCell width={200}>
                <Text as="h3" variant="copy1">
                  {props.strings.thirdParty
                    ? props.strings.thirdParty
                    : 'Third Party'}
                  {' / '}
                  {props.strings.firstParty
                    ? props.strings.firstParty
                    : 'First Party'}{' '}
                  {props.strings.cookie ? props.strings.cookie : 'Cookie'}
                </Text>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.cookies.map((cc, i) =>
              cc.cookies.map((c, k) => {
                return (
                  <TableRow key={`${i}_${k}`}>
                    <TableCell>
                      <Text as="p" variant="copy1">
                        {c.title}
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text as="p" variant="copy1">
                        {c.description}
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text as="p" variant="copy1">
                        {cc.title}
                      </Text>
                    </TableCell>

                    <TableCell>
                      <Text as="p" variant="copy1">
                        {c.expiration ? c.expiration : '-'}
                      </Text>
                    </TableCell>

                    <TableCell>
                      <Text as="p" variant="copy1">
                        {c.thirdParty
                          ? props.strings.thirdParty
                            ? props.strings.thirdParty
                            : 'Third Party'
                          : props.strings.firstParty
                          ? props.strings.firstParty
                          : 'First Party'}
                      </Text>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export { CookieInformations, CookieInformationsProps };
