import React from 'react';
import { NotImplemented } from '../../utils/NotImplemented';
import { useIsTheme } from '../../theming';
import { MuteIconAudi } from './MuteIconAudi';
import { MuteIconNeutral } from './MuteIconNeutral';

const MuteIcon = () => {
  const theme = useIsTheme();
  if (theme.audi) {
    return <MuteIconAudi />;
  } else if (theme.neutral) {
    return <MuteIconNeutral />;
  } else return <NotImplemented />;
};
/** @deprecated Use VolumeIcon instead */
export const MuteWrapper = MuteIcon;
export { MuteIcon };
