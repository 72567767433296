import React from 'react';
import { makeStyles, SvgIcon } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  svgWrapper: {
    fontSize: '30px',
    color: '#7C7C7C',
    cursor: 'pointer',
    '&:hover': {
      color: '#000000',
    },
  },
}));
const MailIconLight = () => {
  const cls = useStyles();
  return (
    <SvgIcon className={cls.svgWrapper}>
      <path
        d="M4.01 6.03l7.51 3.22-7.52-1 .01-2.22m7.5 8.72L4 17.97v-2.22l7.51-1M2.01 3L2 10l15 2-15 2 .01 7L23 12 2.01 3z"
        stroke="currentColor"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export { MailIconLight };
