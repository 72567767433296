import React from 'react';
import { TableCell as MuiTableCell } from '@material-ui/core';

interface TableCellProps {
  noBorder?: boolean;
  center?: boolean;
  noLimitWidth?: boolean;
  style?: Object;
  colSpan?: number;
  buttons?: boolean;
  noLimitButtons?: boolean;
}

/**
 * Audi Styled Table Cell
 */

// TODO: Make props more precise.
const TableCell: React.FC<TableCellProps> = (props) => {
  const s: Object = props.style || {};
  if (props.noBorder) s['borderBottom'] = 'none';
  if (!props.noLimitWidth) s['maxWidth'] = '200px';
  if (props.buttons) {
    s['maxWidth'] = '50px';
    s['textAlign'] = 'right';
  }
  if (props.noLimitButtons) {
    s['textAlign'] = 'right';
  }
  s['verticalAlign'] = props.center ? 'center' : 'top';
  return (
    <MuiTableCell align="left" style={s} colSpan={props.colSpan}>
      {props.children}
    </MuiTableCell>
  );
};

export { TableCell, TableCellProps };
