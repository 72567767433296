import { createStyles } from '@material-ui/core';

export const tableStyles = () =>
  createStyles({
    table: {
      minWidth: '100%',
      borderSpacing: '0 8px',
      borderCollapse: 'separate',
    },
    btnLeft: {
      marginRight: '3px',
    },
    btnMiddle: {
      marginLeft: '3px',
      marginRight: '3px',
    },
    btnRight: {
      marginLeft: '3px',
    },
    timeCodeContainer: {
      maxWidth: '100px',
      display: 'inline-block',
      verticalAlign: 'top',
      marginLeft: '3px',
      marginRight: '3px',
    },
  });
