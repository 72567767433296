import React from 'react';
import { NotImplemented } from '../../utils/NotImplemented';
import { useIsTheme } from '../../theming';
import { HandRaiseUpAudi } from './HandRaiseUpAudi';
import { HandRaiseDownAudi } from './HandRaiseDownAudi';
import { HandRaiseUpNeutral } from './HandRaiseUpNeutral';
import { HandRaiseDownNeutral } from './HandRaiseDownNeutral';

interface P {
  active: boolean;
}

const HandRaiseIcon = (props: P) => {
  const theme = useIsTheme();
  if (theme.audi) {
    if (props.active) {
      return <HandRaiseUpAudi />;
    } else {
      return <HandRaiseDownAudi />;
    }
  } else if (theme.neutral) {
    if (props.active) {
      return <HandRaiseUpNeutral />;
    } else {
      return <HandRaiseDownNeutral />;
    }
  } else return <NotImplemented />;
};

export { HandRaiseIcon };
