import React from 'react';
import { SvgIcon } from '@material-ui/core';

const UserIconAudi = () => (
  <SvgIcon fontSize="inherit" width="24" height="24" viewBox="0 0 24 24">
    <g id="user-small" transform="translate(3.5 1.565)">
      <path
        id="Shape"
        d="M.5,9.5v-8c0-.62.361-1,1.125-1h15.6c.764,0,1.277.379,1.279,1v8H.5Z"
        transform="translate(-1 11.435)"
        fill="none"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-width="1"
      />
      <rect
        id="Rectangle-path"
        width="9"
        height="9"
        rx="2"
        transform="translate(4 -0.065)"
        fill="none"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-width="1"
      />
    </g>
    <rect
      id="Rechteck_71"
      data-name="Rechteck 71"
      width="24"
      height="24"
      fill="none"
    />
  </SvgIcon>
);

export { UserIconAudi };
