import React from 'react';
import { SvgIcon } from '@material-ui/core';

const RemoveIconNeutral = () => {
  return (
    <SvgIcon height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF">
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path
        fill="#FFFFFF"
        d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
      />
    </SvgIcon>
  );
};

export { RemoveIconNeutral };
