import React from 'react';
import { NotImplemented } from '../../utils/NotImplemented';
import { useIsTheme } from '../../theming';
import { LanguageIconDark } from './LanguageIconDark';
import { LanguageIconLight } from './LanguageIconLight';
import { LanguageIconAudi } from './LanguageIconAudi';

const LanguageIcon = () => {
  const theme = useIsTheme();

  if (theme.neutralDark) {
    return <LanguageIconDark />;
  } else if (theme.neutralLight) {
    return <LanguageIconLight />;
  } else if (theme.audi) {
    return <LanguageIconAudi />;
  } else return <NotImplemented />;
};

export { LanguageIcon };
