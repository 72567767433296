import React from 'react';
import { NotImplemented } from '../../utils/NotImplemented';
import { useIsTheme } from '../../theming';
import { MenuIconNeutral } from './MenuIconNeutral';
import { MenuIconAudi } from './MenuIconAudi';

const MenuIcon = () => {
  const theme = useIsTheme();
  if (theme.audi) {
    return <MenuIconAudi />;
  } else if (theme.neutral) {
    return <MenuIconNeutral />;
  } else return <NotImplemented />;
};

export { MenuIcon };
