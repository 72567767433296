import React from 'react';
import { NotImplemented } from '../../utils/NotImplemented';
import { useIsTheme } from '../../theming';
import { LikeIconInactiveAudi } from './LikeIconInactiveAudi';
import { LikeIconInactiveLight } from './LikeIconInactiveLight';
import { LikeIconInactiveDark } from './LikeIconInactiveDark';

const LikeIconInactive = () => {
  const theme = useIsTheme();
  if (theme.audi) {
    return <LikeIconInactiveAudi />;
  } else if (theme.neutralLight) {
    return <LikeIconInactiveLight />;
  } else if (theme.neutralDark) {
    return <LikeIconInactiveDark />;
  } else return <NotImplemented />;
};
export { LikeIconInactive };
