import React from 'react';
import { NotImplemented } from '../../utils/NotImplemented';
import { useIsTheme } from '../../theming';
import { LiveIconAudi } from './LiveIconAudi';
import { LiveIconNeutral } from './LiveIconNeutral';

const LiveIcon = () => {
  const theme = useIsTheme();
  if (theme.audi) {
    return <LiveIconAudi />;
  } else if (theme.neutral) {
    return <LiveIconNeutral />;
  } else return <NotImplemented />;
};

export { LiveIcon };
