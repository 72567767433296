import React from 'react';
import { SvgIcon } from '@material-ui/core';

const CancelIconAudi = () => (
  <SvgIcon>
    <path
      d="M5.9,17.1 L17.1,5.9 M5.9,5.9 L17.1,17.1"
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export { CancelIconAudi };
