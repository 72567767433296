import React, { Component } from 'react';
import { Rating, RatingProps } from '@material-ui/lab';
import { createStyles, withStyles } from '@material-ui/core';
import { FavoriteIcon } from '@pp-labs/ui-components';

const whiteStyle = () =>
  createStyles({
    icon: {
      padding: '0 12px 0 0',
      color: 'black',
    },
    iconFilled: {
      color: 'black',
    },
    iconHover: {
      color: 'black',
    },
  });

const WhiteRating = withStyles(whiteStyle)(Rating);

interface P {
  identifier: number;
  emit: (name: number, value: number) => void;
  value: number;
  disabled?: boolean;
  maxStars: number;
}

class Stars extends Component<P> {
  state = {
    rating: null,
  };

  render() {
    const ratingProps: RatingProps = {
      name: this.props.identifier.toString(),
      size: 'medium',
      defaultValue: 0,
      getLabelText: (value: number) => `${value} Star${value !== 1 ? 's' : ''}`,
      precision: 1,
      value: this.props.value,
      icon: <FavoriteIcon fontSize="inherit" filled={true} />,
      emptyIcon: <FavoriteIcon fontSize="inherit" />,
      max: this.props.maxStars,
    };
    if (this.props.disabled) {
      ratingProps.readOnly = true;
    } else {
      ratingProps.onChange = (
        _event: React.ChangeEvent<{}>,
        newValue: number | null
      ) => {
        this.props.emit(this.props.identifier, newValue || 0);
      };
    }

    return <WhiteRating {...ratingProps} />;
  }
}

export default Stars;
