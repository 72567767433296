import React, { createContext, FC, useContext, useState } from 'react';
import {
  AudiPlatformProvider,
  LocaleProvider,
  Locales,
} from '@audi/audi-ui-react';
import { ButtonColors } from './Hooks';

export interface Theme {
  name: 'audi' | 'neutral';
  variant?: 'dark' | 'light';
  buttonColors?: ButtonColors;
}

interface ThemeContext {
  theme: Theme;
  setTheme: (theme: Theme) => void;
  buttonColors?: ButtonColors;
}

const defaultTheme: Theme = {
  name: 'audi',
};
export const ThemeContext = createContext<ThemeContext>({
  theme: defaultTheme,
  setTheme: () => {},
  buttonColors: undefined,
});

export const ThemeProvider: FC = (props) => {
  const [currentTheme, setCurrentTheme] = useState<Theme>(defaultTheme);
  return (
    <ThemeContext.Provider
      value={{
        theme: currentTheme,
        setTheme: setCurrentTheme,
        buttonColors: undefined,
      }}
    >
      <AudiPlatformProvider>
        <LocaleProvider locale={Locales.en_US}>{props.children}</LocaleProvider>
      </AudiPlatformProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext).theme;
export const useSetTheme = () => useContext(ThemeContext).setTheme;
