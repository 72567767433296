import React from 'react';
import { NotImplemented } from '../../utils/NotImplemented';
import { useIsTheme } from '../../theming';
import { MailIconDark } from './MailIconDark';
import { MailIconLight } from './MailIconLight';
import { MailIconAudi } from './MailIconAudi';

const MailIcon = () => {
  const theme = useIsTheme();

  if (theme.neutralDark) {
    return <MailIconDark />;
  } else if (theme.neutralLight) {
    return <MailIconLight />;
  } else if (theme.audi) {
    return <MailIconAudi />;
  } else return <NotImplemented />;
};
/** @deprecated Use MailIcon instead */
export const MailIconWrapper = MailIcon;
export { MailIcon };
