import React from 'react';
import { NotImplemented } from '../../utils/NotImplemented';
import { useIsTheme } from '../../theming';
import { PublicIconDark } from './PublicIconDark';
import { PublicIconAudi } from './PublicIconAudi';

const PublicIcon = () => {
  const theme = useIsTheme();
  if (theme.neutralDark) {
    return <PublicIconDark />;
  } else if (!theme.neutralDark) {
    return <PublicIconAudi />;
  } else return <NotImplemented />;
};

export { PublicIcon };
