import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  CurrentSurveyValues,
  GetSurveyTemplate,
  GetUserSurvey,
  SendUserSurvey,
} from './Interfaces';
import { makeStyles } from '@material-ui/core';
import {
  getIndention,
  sortItems,
  useGetLocalizedValue,
  useSurveyContext,
} from './utils';
import { Text } from '@pp-labs/ui-components';
import { LabelButton } from '@pp-labs/ui-components';
import { SurveyObject } from './SurveyObject';

export interface GeneralSurveyProps {
  channelId?: number;
  id: number;
  context: string;
  accessor: (v: string) => string;
  live: boolean;
  onFinish?: null | (() => void);
  templateId?: number;
}

const useStyles = makeStyles({
  btn: {
    width: 'auto',
    marginTop: '36px',
  },
});

export const GeneralSurvey = (props: GeneralSurveyProps) => {
  const context = useSurveyContext();
  const mainRef = useRef<HTMLDivElement | null>(null);
  const getLocalized = useGetLocalizedValue();
  const [template, setTemplate] =
    useState<GetSurveyTemplate | undefined>(undefined);

  const [hasData, setHasData] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [renders, setRenders] = useState<number>(0);

  const [currentValues, setCurrentValues] = useState<CurrentSurveyValues[]>([]);

  const cls = useStyles();

  const getEndpoint = (method: 'get' | 'post'): string => {
    switch (props.context) {
      case 'session':
      case 'liveSession':
        return `sessions/${props.id}/surveys`;
      case 'lot':
        return `trainings/${props.id}/surveys`;
      default:
        return method === 'post'
          ? context.endpoints.postSurvey
          : context.endpoints.getSurvey;
    }
  };

  useEffect(() => {
    const getTemplate = async () => {
      const data: GetSurveyTemplate[] = (
        await context.client.get(context.endpoints.getTemplates)
      ).data;
      return data.find(
        (t) =>
          (props.channelId ? t.channelId === props.channelId : true) &&
          t.surveyContext === props.context &&
          (!props.templateId || props.templateId === t.surveyTemplateId)
      );
    };
    const loadAnswer = async (
      t: GetSurveyTemplate
    ): Promise<GetUserSurvey | undefined> => {
      try {
        const res = (
          await context.client.get<GetUserSurvey[] | GetUserSurvey>(
            getEndpoint('get')
          )
        ).data;
        if (Array.isArray(res)) {
          return res.find((r) => r.surveyTemplateId === t.surveyTemplateId);
        } else {
          return res.surveyTemplateId === t.surveyTemplateId ? res : undefined;
        }
      } catch {
        return undefined;
      }
    };

    const init = async (t: GetSurveyTemplate) => {
      t.templateItems = sortItems(t.templateItems);
      const data = await loadAnswer(t);
      const d = !!data?.surveyItems?.length;
      const curr = t.templateItems.map((item) => {
        const result = data?.surveyItems?.find(
          (i) => i.surveyTemplateItemId === item.surveyTemplateItemId
        );
        return {
          ...item,
          indentParent: getIndention(item, t.templateItems),
          currentVal: result?.surveyValue || '',
        };
      });
      setCurrentValues(curr);
      setHasData(d);
      setTemplate(t);
    };
    const fetch = async () => {
      const template = await getTemplate();
      if (!template) return;
      await init(template);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.context, submitted, props.id]);

  const submitToServer = async () => {
    const data: SendUserSurvey = {
      surveyTemplateId: template?.surveyTemplateId || 0,
      originId: props.id,
      surveyItems: displayValues
        .filter((v) => v.surveyType !== 'headline')
        .map((v) => ({
          surveyTemplateItemId: v.surveyTemplateItemId,
          surveyValue: v.currentVal || '',
        })),
    };
    try {
      await context.client.post(getEndpoint('post'), data);
    } catch {}
    setSubmitted(true);
    if (context.scrollToTop)
      mainRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    props.onFinish?.();
  };

  const surveyTitle = useMemo(() => {
    return getLocalized(template?.title || '');
  }, [template]);

  const isBtnDisabled = () =>
    displayValues.some(
      (v) =>
        v.surveyType === 'rating' && (!v.currentVal || v.currentVal === '0')
    );

  const emit = (index: number, value: string) => {
    currentValues[index].currentVal = value;
    setRenders(renders + 1);
  };

  const displayValues = useMemo(() => {
    if (!context.importerLogic) return currentValues;
    return currentValues.filter(
      (v) =>
        v.surveyGroup === 'Both' ||
        v.surveyGroup === (props.live ? 'Importer' : 'Dealer')
    );
  }, [currentValues]);

  if (!template) return null;

  return (
    <div ref={mainRef}>
      <Text as="h2" variant={'order2'} weight={'bold'}>
        {surveyTitle}
      </Text>
      {displayValues.map((item, index) => {
        return (
          <SurveyObject
            item={item}
            disabled={hasData}
            emit={emit}
            index={index}
            strings={context.strings}
          />
        );
      })}

      {!hasData ? (
        <LabelButton
          type="submit"
          variant="primary"
          className={cls.btn}
          disabled={isBtnDisabled()}
          spaceStackStart={'l'}
          onClick={submitToServer}
        >
          Send
        </LabelButton>
      ) : (
        <Text
          as="p"
          variant="copy1"
          spaceStackEnd={'xxxl'}
          spaceStackStart={'l'}
        >
          {context.strings.thanksForFeedback}
        </Text>
      )}
    </div>
  );
};
