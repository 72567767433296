import React from 'react';
import { SvgIcon } from '@material-ui/core';

const MenuIconAudi = () => (
  <SvgIcon fontSize="inherit">
    <path
      d="M3,15.5 L21,15.5 M3,11.5 L21,11.5 M3,7.5 L21,7.5"
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export { MenuIconAudi };
