import React from 'react';
import { SvgIcon } from '@material-ui/core';

const MailIconAudi = () => (
  <SvgIcon>
    <path
      d="M22.5,19.5 L3.5,19.5 C2.4,19.5 1.5,18.6 1.5,17.5 L1.5,4.5 L20.5,4.5 C21.6,4.5 22.5,5.4 22.5,6.5 L22.5,19.5 Z M5,9.5 L12,15 L19,9.5"
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export { MailIconAudi };
