import {
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { CancelIcon } from '../icons';
import React, { ReactElement, useState } from 'react';
import {
  Accordion,
  AccordionSection,
  Divider,
  Text,
  Toggle,
} from '@audi/audi-ui-react';
import { AudiDialog } from './AudiDialog';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { Spacer } from './Spacer/Spacer';
import { LabelButton } from './LabelButton/LabelButton';

export interface CookieCategory {
  id: string;
  title: any;
  description: any;
  cookies: CookieOption[];
  required?: boolean;
}

export interface CookieOption {
  id: string;
  title: any;
  description: any;
  enabled?: boolean;
  required?: boolean;
  expiration?: string;
  thirdParty?: boolean;
}

interface CookieDialogProps {
  header?: ReactElement;
  body?: ReactElement;
  cookieCategories: CookieCategory[];
  close: null | (() => void);
  save?: (cookieCategories: CookieCategory[]) => void;
  maxWidth?: Breakpoint;
  top?: boolean;
  autoAdaptWidth?: boolean;
  strings?: any;
}

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: 'baseline', // default center
  },
  headline: {
    maxWidth: 'calc(100% - 30px)',
  },
  sameLine: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

/**
 * Cookie Dialog to accept or reject cookies
 */

const CookieDialog: React.FC<CookieDialogProps> = (props) => {
  const values = props.cookieCategories;
  const [updateRender, setUpdateRender] = useState<number>(0);
  const close = () => {
    if (props.close) props.close();
  };
  const classes = useStyles();

  const maxWidth = props.maxWidth || 'sm';
  const o = props.top ? { scrollPaper: classes.scrollPaper } : undefined;
  return (
    <div>
      <AudiDialog
        open={true}
        onClose={close}
        aria-labelledby="form-dialog-title"
        fullWidth={!props.autoAdaptWidth}
        maxWidth={maxWidth}
        classes={o}
      >
        <DialogTitle id="form-dialog-title">
          {props.header ? (
            props.header
          ) : (
            <Text
              as="h2"
              variant="order2"
              className={props.autoAdaptWidth ? undefined : classes.headline}
            >
              <b>
                {props.strings.cookieSettings
                  ? props.strings.cookieSettings
                  : 'Cookie Settings'}
              </b>
            </Text>
          )}

          {props.close && (
            <IconButton
              onClick={close}
              style={{ position: 'absolute', right: '6px', top: '6px' }}
            >
              <CancelIcon />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent>
          {props.body ? (
            props.body
          ) : (
            <Text as="p" variant="copy2">
              Wir, die AUDI AG, Auto-Union-Straße 1, 85057 Ingolstadt, allein
              oder in Zusammenarbeit mit unseren verbundenen Unternehmen und
              Partnern ("Wir", "Unser"), nutzen auf unserer Website eigene und
              Dienste Dritter, die Cookies und ähnliche Technologien verwenden
              ("Dienste"), die uns helfen, unsere Website zu verbessern, den
              Datenverkehr und die Nutzung zu analysieren und auf Ihre
              Interessen zugeschnittene Inhalte anzuzeigen, einschließlich
              personalisierter Werbung. Um diese Dienste nutzen zu können,
              benötigen wir Ihre Einwilligung. Mit einem Klick auf "Alle
              akzeptieren" erteilen Sie Ihre Einwilligung zur Verwendung aller
              Dienste. Sie können auch einzelne Einwilligungen erteilen, indem
              Sie die Schieberegler für jede Cookie-Kategorie einzeln anklicken
              und diese Einstellungen durch Klicken auf "Einstellungen speichern
              und fortfahren" speichern. Falls Sie keinen der Schieberegler
              anklicken, werden nur die notwendigen Cookies (z. B. der Ensighten
              Privacy Manager, unser Einwilligungsmanagementtool) verwendet. Sie
              sind nicht gesetzlich verpflichtet, in die Verwendung von Cookies
              einzuwilligen, aber wenn Sie Ihre Einwilligung nicht erteilen,
              können Sie bestimmte unserer Dienste möglicherweise nicht nutzen.
              Sie können Ihre Cookie-Einstellungen anhand der unten aufgeführten
              Kategorien von Cookies verwalten. Sie können Ihre Einwilligung
              jederzeit mit Wirkung zum Zeitpunkt des Widerrufs widerrufen. Für
              den Widerruf der Einwilligung beachten Sie bitte die
              "Cookie-Einstellungen" in der Fußzeile der Webseite. Weitere
              Informationen sowie konkrete Hinweise zur Verwendung Ihrer
              personenbezogenen Daten finden Sie in unserer{' '}
              <a
                href="https://www.audi.com/en/cookie-policy.html?privacySource=1"
                target="_blank"
              >
                Cookie Information
              </a>
              , unserem Datenschutzhinweis und im Impressum.
            </Text> //TODO LINKS
          )}

          <Spacer spaceStackStart={'s'} />

          <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <LabelButton
              type="submit"
              variant="primary"
              stretch
              onClick={() => {
                values?.forEach((cc: CookieCategory) => {
                  cc.cookies.forEach((c: CookieOption) => (c.enabled = true));
                });
                setUpdateRender(updateRender + 1);
                if (props.save) props.save(values);
              }}
            >
              {props.strings.acceptAll ? props.strings.acceptAll : 'Accept All'}
            </LabelButton>
            <LabelButton
              type="submit"
              variant="secondary"
              stretch
              onClick={() => {
                if (props.save) props.save(values);
              }}
            >
              {props.strings.saveSettingsClose
                ? props.strings.saveSettingsClose
                : 'Save Settings and Close'}
            </LabelButton>
          </div>

          <Spacer spaceStackStart={'s'} />
          <Divider />
          <Spacer spaceStackStart={'s'} />

          {values.map((cc: CookieCategory, k: number) => {
            return (
              <div key={`category_${k}`}>
                <div className={classes.sameLine}>
                  <div style={{ flex: 1 }}>
                    <Text as="h2" variant="copy1">
                      {cc.title}
                    </Text>
                    <Text as="p" variant="copy2">
                      {cc.description}
                    </Text>
                  </div>
                  <div>
                    {!cc.required && (
                      <Toggle
                        inputId={`category_${k}`}
                        onChange={(nextValue: boolean) => {
                          cc.cookies.forEach((element: CookieOption) => {
                            element.enabled = nextValue;
                          });
                          setUpdateRender(updateRender + 1);
                        }}
                      />
                    )}
                  </div>
                </div>

                {cc.cookies.length > 0 && (
                  <>
                    <Spacer spaceStackStart={'s'} />
                    <Accordion>
                      <AccordionSection
                        id={`acc_category_${k}`}
                        key={`acc_category_${k}`}
                        headingLevel={'h6'}
                        label={
                          props.strings.details
                            ? props.strings.details
                            : 'Details'
                        }
                      >
                        {cc.cookies.map((c: CookieOption, j: number) => {
                          return (
                            <div
                              className={classes.sameLine}
                              key={`cookie_${j}`}
                            >
                              <div style={{ flex: 1 }}>
                                <Text as="p" variant="copy1">
                                  {c.title}
                                </Text>
                                <Text as="p" variant="copy2">
                                  {c.description}
                                </Text>
                              </div>
                              <Toggle
                                disabled={c.required}
                                inputId={`cookie_${j}`}
                                on={c.required || c.enabled}
                                onChange={(nextValue: boolean) => {
                                  c.enabled = nextValue;
                                  setUpdateRender(updateRender + 1);
                                }}
                              />
                            </div>
                          );
                        })}
                      </AccordionSection>
                    </Accordion>
                  </>
                )}

                <Spacer spaceStackStart={'s'} />
                <Divider />
                <Spacer spaceStackStart={'s'} />
              </div>
            );
          })}
        </DialogContent>
      </AudiDialog>
    </div>
  );
};

export { CookieDialog, CookieDialogProps };
