import React from 'react';
import { useIsTheme } from '../../theming';
import { SearchIconNeutral } from './SearchIconNeutral';

const SearchIcon = () => {
  const theme = useIsTheme();

  if (theme.neutral) {
    return <SearchIconNeutral />;
  } else return null;
};
/** @deprecated Use SearchIcon instead */
export const SearchIconWrapper = SearchIcon;
export { SearchIcon };
