import React from 'react';
import { NotImplemented } from '../../utils/NotImplemented';
import { useIsTheme } from '../../theming';
import { ViewIconAudi } from './ViewIconAudi';
import { ViewIconNeutral } from './ViewIconNeutral';

const ViewIcon = () => {
  const theme = useIsTheme();
  if (theme.audi) {
    return <ViewIconAudi />;
  } else if (theme.neutral) {
    return <ViewIconNeutral />;
  } else return <NotImplemented />;
};

export { ViewIcon };
