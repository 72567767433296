import React from 'react';
import { SvgIcon } from '@material-ui/core';

const DownloadIconAudi = () => (
  <SvgIcon>
    <path
      d="M15,15.5 L8,15.5 M11.5,7 L11.5,13.4 M8.3,10.2 L11.5,13.4 L14.7,10.2 M11.5,22.5 C17.5751322,22.5 22.5,17.5751322 22.5,11.5 C22.5,5.42486775 17.5751322,0.5 11.5,0.5 C5.42486775,0.5 0.5,5.42486775 0.5,11.5 C0.5,17.5751322 5.42486775,22.5 11.5,22.5 Z"
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export { DownloadIconAudi };
