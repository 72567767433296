import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const PlusIconAudi = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" {...props}>
    <g id="system-add-small">
      <path
        id="Path"
        d="M0,0V9.391"
        transform="translate(11.964 7.304)"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <path
        id="Path-2"
        data-name="Path"
        d="M9.391,0H0"
        transform="translate(7.304 11.924)"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <circle
        id="Oval"
        cx="11.478"
        cy="11.478"
        r="11.478"
        transform="translate(0.522 0.522)"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </g>
    <rect
      id="Rechteck_74"
      data-name="Rechteck 74"
      width="24"
      height="24"
      fill="none"
    />
  </SvgIcon>
);

export { PlusIconAudi };
