import React, { FC } from 'react';
import { makeThemedStyles, useThemedStyles } from '../utils/makeThemedStyles';

const styles = makeThemedStyles({
  sliderWrapper: {
    padding: '0 64px 32px 64px',
    background: ({ colorScheme }) => colorScheme.secondary,
  },
});

export const SliderWrapper: FC = (props) => {
  const cls = useThemedStyles(styles);
  return <div className={cls.sliderWrapper}>{props.children}</div>;
};
