import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { Text } from '@audi/audi-ui-react';

const useStyles = makeStyles({
  wrapper: {
    width: '200px',
    height: '128px',
  },
  innerWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
    border: 'solid 1px #5E5E5E',
    overflow: 'hidden',
    textAlign: 'center',
  },
});

interface P {
  text: string;
  handleClick: () => void;
  icon: FunctionComponent;
}

const BigButton = (props: P) => {
  const cls = useStyles();
  return (
    <div className={cls.wrapper}>
      <div className={cls.innerWrapper} onClick={props.handleClick}>
        <IconButton disabled>
          <props.icon />
        </IconButton>
        <Text variant={'copy1'}>{props.text}</Text>
      </div>
    </div>
  );
};

export { BigButton };
