import React, { FC } from 'react';
// Import can't be shortened as it causes a circular dependency
import { makeThemedStyles, useThemedStyles } from '../utils/makeThemedStyles';

const styles = makeThemedStyles((theme) => {
  return {
    footerWrapper: {
      lineHeight: '32px',
      position: 'absolute',
      bottom: 0,
      width: '100%',
      zIndex: 99,
      background: ({ colorScheme }) => colorScheme.footer,
      height: ({ isTheme }) => (isTheme.neutral ? '50px' : undefined),
      display: ({ isTheme }) => (isTheme.neutral ? 'flex' : undefined),
      alignItems: ({ isTheme }) => (isTheme.neutral ? 'center' : undefined),
      [theme.breakpoints.up('sm')]: {
        padding: '0 24px',
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '0 10px',
        display: 'flex',
        height: '90px !important',
        alignItems: 'center',
        flexWrap: 'wrap',
      },
    },
  };
});

interface FooterProps {
  refForward: React.LegacyRef<HTMLDivElement>;
}

export const FooterWrapper: FC<FooterProps> = (props) => {
  const cls = useThemedStyles(styles);

  return (
    <div className={cls.footerWrapper} ref={props.refForward}>
      {props.children}
    </div>
  );
};
