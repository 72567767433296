import React from 'react';
import { NotImplemented } from '../../utils/NotImplemented';
import { useIsTheme } from '../../theming';
import { PauseIconNeutral } from './PauseIconNeutral';
import { PauseIconAudi } from './PauseIconAudi';
import { SvgIconProps } from '@material-ui/core';

const PauseIcon = (props: SvgIconProps) => {
  const theme = useIsTheme();
  if (theme.audi) {
    return <PauseIconAudi {...props} />;
  } else if (theme.neutral) {
    return <PauseIconNeutral />;
  } else return <NotImplemented />;
};

export { PauseIcon };
