import React from 'react';
import { SpacingLabel } from '@audi/audi-ui-react/dist/types/themes';
import { SpacingShorthandList } from '@audi/audi-ui-react/dist/types/interfaces/layoutProps';
import { LayoutItem, LayoutItemProps } from '@audi/audi-ui-react';
import { useIsTheme } from '../../theming';

export interface SpacerProps extends LayoutItemProps {
  inFlex?: boolean;
  neutralSpacing?: SpacingShorthandList;
  neutralSpaceStackStart?: SpacingLabel;
  neutralSpaceInlineEnd?: SpacingLabel;
  neutralSpaceStackEnd?: SpacingLabel;
  neutralSpaceInlineStart?: SpacingLabel;
}

/**
 * Audi Spacer
 * See Spacings adjusting for different Breakpoints here:
 * https://react.ui.audi/?path=/story/brand-identity-spacing--page
 *
 * This component server as a spacer for both audi and neutral theme.
 *
 * To use it only as audi spacer you can pass following props
 * (spaceStackStart, spaceInlineEnd, spaceStackEnd, spaceInlineStart, spacing)
 *
 * To use it only as a neutral spacer you can pass following props
 * (neutralSpaceStackStart, neutralSpaceInlineEnd, neutralSpaceStackEnd, neutralSpaceInlineStart, neutralSpacing)
 *
 * same for layoutItem
 */

export const Spacer = (props: SpacerProps) => {
  const theme = useIsTheme();
  if (
    theme.neutral &&
    (props.neutralSpaceStackStart ||
      props.neutralSpaceInlineEnd ||
      props.neutralSpaceStackEnd ||
      props.neutralSpaceInlineStart)
  ) {
    return (
      <LayoutItem
        spacing={
          props.spacing || [
            props.neutralSpaceStackStart,
            props.neutralSpaceInlineEnd,
            props.neutralSpaceStackEnd,
            props.neutralSpaceInlineStart,
          ]
        }
      >
        <div
          style={{
            overflow: 'hidden',
          }}
        ></div>
      </LayoutItem>
    );
  } else {
    return (
      <LayoutItem
        spacing={
          props.spacing || [
            props.spaceStackStart,
            props.spaceInlineEnd,
            props.spaceStackEnd,
            props.spaceInlineStart,
          ]
        }
      >
        <div
          style={{
            overflow: 'hidden',
          }}
        ></div>
      </LayoutItem>
    );
  }
};
