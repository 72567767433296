import {
  GetSurveyTemplate,
  PostSurveyTemplate,
  PostSurveyTemplateItem,
} from './Interfaces';
import { SurveyProviderValue } from './SurveyProvider';

type Copied = {
  newId: number;
  oldId: number;
};

export const duplicate = async (
  survey: GetSurveyTemplate,
  context: Required<SurveyProviderValue>,
  setDuplicating: (duplicating: boolean) => void,
  refresh: () => Promise<void>
) => {
  setDuplicating(true);
  context.notify('Starting duplication...', 'success');
  // Duplicating the surveyTemplate
  const mainSurveyData: PostSurveyTemplate = {
    ...survey,
    thumbnail: undefined,
  };
  const { id } = (
    await context.client.post<{ id: number }>(
      context.endpoints.postTemplate,
      mainSurveyData
    )
  ).data;

  const copied: Copied[] = [];

  // deep duplicating all surveyTemplateItems
  while (true) {
    const nextToCopy = survey.templateItems.find(
      (i) =>
        // not already copied
        !copied.some((c) => c.oldId === i.surveyTemplateItemId) &&
        // parent item
        (i.indentParent === 0 ||
          // or parent item already copied
          copied.some((c) => c.oldId === i.indentParent))
    );
    if (!nextToCopy) {
      break;
    }
    const parent = nextToCopy.indentParent
      ? copied.find((c) => c.oldId === nextToCopy.indentParent)?.newId
      : undefined;
    const copy: PostSurveyTemplateItem = {
      ...nextToCopy,
      indentParent: parent || 0,
    };
    const res = (
      await context.client.post<number>(
        `${context.endpoints.postTemplate}/${id}/item`,
        copy
      )
    ).data;
    copied.push({ oldId: nextToCopy.surveyTemplateItemId, newId: res });
  }
  context.notify('Duplication finished', 'success');
  setDuplicating(false);
  await refresh();
};
