import { DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { CancelIcon } from '../icons';
import React from 'react';
import { Text } from '../base';
import { AudiDialog } from './AudiDialog';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { makeThemedStyles, useThemedStyles } from './makeThemedStyles';

interface EditDialogProps {
  title: string;
  close: null | (() => void);
  maxWidth?: Breakpoint;
  top?: boolean;
  autoAdaptWidth?: boolean;
  closeCrossOnly?: boolean;
  cms?: boolean;
  dark?: boolean;
}

const styles = makeThemedStyles({
  scrollPaper: {
    alignItems: 'baseline', // default center
  },
  headline: {
    maxWidth: 'calc(100% - 30px)',
  },
  closeButton: {
    position: 'absolute',
    right: '6px',
    top: '6px',
    color: ({ isTheme }) => (isTheme.neutralDark ? 'white' : undefined),
  },
  paper: {
    backgroundColor: ({ isTheme }) =>
      isTheme.neutralDark ? 'black' : undefined,
    boxShadow: ({ isTheme }) => (isTheme.neutral ? 'none' : undefined),
    border: ({ isTheme }) =>
      isTheme.neutralDark ? '1px solid white' : undefined,
  },
});

/**
 * Useful Wrapper for Audi Dialog
 */

const EditDialog: React.FC<EditDialogProps> = (props) => {
  const cls = useThemedStyles(styles);
  const close = () => {
    if (props.close) props.close();
  };
  const maxWidth = props.maxWidth || 'lg';

  const o = props.top ? { scrollPaper: cls.scrollPaper } : undefined;
  return (
    <div>
      <AudiDialog
        open={true}
        onClose={props.closeCrossOnly ? undefined : close}
        aria-labelledby="form-dialog-title"
        fullWidth={!props.autoAdaptWidth}
        maxWidth={maxWidth}
        classes={o}
        disableEnforceFocus
        PaperProps={{
          style: {
            padding: props.cms ? '30px' : undefined,
            backgroundColor: props.dark ? 'black' : undefined,
          },
          className: cls.paper,
        }}
      >
        <DialogTitle id="form-dialog-title">
          <Text
            as="p"
            variant="order3"
            className={props.autoAdaptWidth ? undefined : cls.headline}
          >
            <b>{props.title}</b>
          </Text>
          {props.close && (
            <IconButton
              onClick={close}
              style={{ color: props.dark ? 'white' : undefined }}
              className={cls.closeButton}
            >
              <CancelIcon />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent>{props.children}</DialogContent>
      </AudiDialog>
    </div>
  );
};

export { EditDialog, EditDialogProps };
