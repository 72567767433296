import React from 'react';
import { useIsTheme } from '../../theming';
import { LockIconAudi } from './LockIconAudi';
import { LockIconNeutral } from './LockIconNeutral';

const LockIcon = () => {
  const theme = useIsTheme();
  if (theme.audi) {
    return <LockIconAudi />;
  } else return <LockIconNeutral />;
};
/** @deprecated Use LockIcon instead */
export const LockIconWrapper = LockIcon;
export { LockIcon };
