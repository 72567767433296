import { Theme, useTheme } from './ThemeProvider';

export interface IsTheme {
  audi: boolean;
  neutral: boolean;
  neutralLight: boolean;
  neutralDark: boolean;
}

export type ButtonColors = {
  primaryButton?: string;
  secondaryButton?: string;
  primaryText?: string;
  secondaryText?: string;
  primaryHover?: string;
  secondaryHover?: string;
  primaryDisabled?: string;
  secondaryDisabled?: string;
  primaryDisabledText?: string;
  secondaryDisabledText?: string;
};

interface ColorProperties {
  background?: string;
  navigation?: string;
  footer?: string;
  text?: string;
  icon?: string;
  primary?: string;
  secondary?: string;
  supportForm?: string;
  buttons: ButtonColors;
}

export interface ThemeConfig {
  isTheme: IsTheme;
  colorScheme: ColorProperties;
}

const getIsTheme = (theme: Theme) => {
  return {
    audi: theme.name === 'audi',
    neutral: theme.name === 'neutral',
    neutralLight: theme.name === 'neutral' && theme.variant === 'light',
    neutralDark: theme.name === 'neutral' && theme.variant === 'dark',
  };
};

export const useIsTheme = (): IsTheme => {
  const theme = useTheme();
  return getIsTheme(theme);
};

const defaultLight: Required<ButtonColors> = {
  primaryButton: '#012A5E',
  secondaryButton: '#EFEFEF',
  primaryText: '#FFFFFF',
  secondaryText: '#011D42',
  primaryHover: '#011D42',
  secondaryHover: '#012A5E',
  primaryDisabled: '#749bcd',
  primaryDisabledText: '#FFFFFF',
  secondaryDisabled: '#EFEFEF',
  secondaryDisabledText: '#a0a0a0',
};

const defaultDark: Required<ButtonColors> = {
  primaryButton: '#023D49',
  secondaryButton: '#151515',
  primaryText: '#FFFFFF',
  secondaryText: '#767676',
  primaryHover: '#012A33',
  secondaryHover: '#FFFFFF',
  primaryDisabled: '#3e727d',
  primaryDisabledText: '#FFFFFF',
  secondaryDisabled: '#151515',
  secondaryDisabledText: '#5a5a5a',
};

const applyDefault = (
  specified: ButtonColors | undefined,
  defaultScheme: Required<ButtonColors>
): Required<ButtonColors> => {
  const result = {};
  Object.entries(defaultScheme).forEach((e) => {
    const [key, value] = e;
    result[key] = specified?.[key] ? `#${specified[key]}` : value;
  });
  return result as Required<ButtonColors>;
};

// It returns the object of color scheme for that theme
// by default if no theme is selected it will return audi theme
export const getColorScheme = (
  isTheme: IsTheme,
  theme: Theme
): ColorProperties => {
  const buttons = theme.buttonColors;
  if (isTheme.neutralLight) {
    const btn = applyDefault(buttons, defaultLight);
    return {
      background: '#EFEFEF',
      navigation: '#FFFFFF',
      text: '#000000',
      icon: '#767676',
      footer: '#767676',
      primary: '#012A5E',
      secondary: '#EFEFEF',
      buttons: btn,
    };
  } else if (isTheme.neutralDark) {
    const btn = applyDefault(buttons, defaultDark);
    return {
      background: '#151515',
      navigation: '#000000',
      text: '#ffffff',
      icon: '#919191',
      footer: '#767676',
      primary: '#023D49',
      secondary: '#151515',
      buttons: btn,
    };
  } else {
    return {
      background: '#FFFFFF',
      navigation: '#FFFFFF',
      text: '#000000',
      footer: '#4C4C4C',
      primary: '#000000',
      secondary: '#F2F2F2',
      buttons: defaultLight,
    };
  }
};

export const useColorScheme = (): ColorProperties => {
  const theme = useTheme();
  const isTheme = getIsTheme(theme);
  return getColorScheme(isTheme, theme);
};

export const useThemeConfig = (): ThemeConfig => {
  const theme = useTheme();
  const isTheme = getIsTheme(theme);
  const color = getColorScheme(isTheme, theme);
  return {
    isTheme: isTheme,
    colorScheme: color,
  };
};
