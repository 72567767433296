import React from 'react';
import { SvgIcon } from '@material-ui/core';

const PauseIconNeutral = () => (
  <SvgIcon fontSize="inherit">
    <path
      d="M9,16h2V8H9V16z M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M12,20c-4.41,0-8-3.59-8-8 s3.59-8,8-8s8,3.59,8,8S16.41,20,12,20z M13,16h2V8h-2V16z"
      strokeWidth="1"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export { PauseIconNeutral };
