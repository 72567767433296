import React from 'react';
import { useIsTheme } from '../../theming';
import { ThumbUpIconFilledAudi } from './ThumbUpIconFilledAudi';
import { ThumbUpIconFilledDark } from './ThumbUpIconFilledDark';
import { ThumbUpIconFilledLight } from './ThumbUpIconFilledLight';
import { NotImplemented } from '../../utils/NotImplemented';

export const ThumbUpIconFilled = () => {
  const theme = useIsTheme();
  if (theme.audi) {
    return <ThumbUpIconFilledAudi />;
  } else if (theme.neutralDark) {
    return <ThumbUpIconFilledDark />;
  } else if (theme.neutralLight) {
    return <ThumbUpIconFilledLight />;
  } else {
    return <NotImplemented />;
  }
};
/** @deprecated Use ThumbUpIconFilled instead */
export const UpFilledWrapper = ThumbUpIconFilled;
