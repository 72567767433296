import React from 'react';
import { Checkbox as AudiCheckbox } from '@audi/audi-ui-react';
import { FormProps } from './FormProps';
import { ErrorMessage } from 'formik';

interface CheckboxProps extends FormProps {
  handle: (name: string, checked: boolean) => void;
  value: string;
  currentValue: boolean;
  manual?: boolean;
  dark?: boolean;
}

const NeutralCheckbox: React.FC<CheckboxProps> = (props) => {
  const handleInput = () => {
    props.handle(
      props.name,
      props.currentValue ? false : props.value === 'true'
    );
  };

  return (
    <div
      className={props.dark ? 'darkCheckbookWrapper' : 'lightCheckbookWrapper'}
    >
      <AudiCheckbox
        inputId={props.name}
        name={props.name}
        onChange={handleInput}
        checked={props.manual ? props.currentValue : undefined}
      >
        {props.children}
        {!!props.error && (
          <ErrorMessage
            name={props.name}
            component="div"
            className="field-error"
          />
        )}
      </AudiCheckbox>
    </div>
  );
};

export { NeutralCheckbox };
