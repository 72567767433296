import React from 'react';
import { NotImplemented } from '../../utils/NotImplemented';
import { useIsTheme } from '../../theming';
import { UnMuteIconAudi } from './UnMuteIconAudi';
import { UnMuteIconNeutral } from './UnMuteIconNeutral';

const UnmuteIcon = () => {
  const theme = useIsTheme();
  if (theme.audi) {
    return <UnMuteIconAudi />;
  } else if (theme.neutral) {
    return <UnMuteIconNeutral />;
  } else return <NotImplemented />;
};

export { UnmuteIcon };
