import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { FavoriteIconProps } from './FavoriteIcon';

const FavoriteIconAudi = (props: FavoriteIconProps) => (
  <SvgIcon fontSize={props.fontSize}>
    <path
      d="M11.5 1.9L14.7 8.3 21.8 9.3 16.7 14.3 17.9 21.4 11.6 18.1 5.3 21.4 6.5 14.3 1.4 9.3 8.5 8.3z"
      stroke="currentColor"
      strokeWidth="1"
      fill={props.filled ? 'inherit' : 'none'}
      fillRule="evenodd"
    />
  </SvgIcon>
);

export { FavoriteIconAudi };
