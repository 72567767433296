import React from 'react';
import {
  TableRow as MuiTableRow,
  TableRowProps as MuiTableRowProps,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  row: {
    backgroundColor: '#DDDDDD',
    marginTop: '20px',
    marginBottom: '20px',
  },
});

/**
 * Audi Styled Table Row
 */

interface TableRowProps extends MuiTableRowProps {
  component?: React.ReactNode;
}

const TableRow: React.FC<TableRowProps> = (props) => {
  const cls = useStyles();
  const { children, className, ...rest } = props;
  const cn = props.className ? `${cls.row} ${props.className}` : cls.row;
  return (
    <MuiTableRow className={cn} {...rest}>
      {children}
    </MuiTableRow>
  );
};

export { TableRow, TableRowProps };
