import {
  Divider as AudiDivider,
  Layout as AudiLayout,
  LayoutItem as AudiLayoutItem,
  LayoutItemProps,
  FormGroup as AudiFormGroup,
  Search as AudiSearch,
  Checkbox,
  Select,
  Loader as AudiLoader,
  Toggle as AudiToggle,
  RadioButton,
  TextField,
  Accordion as AudiAccordion,
  AccordionSection as AudiAccordionSection,
  TextArea,
} from '@audi/audi-ui-react';

// These are stubs and can/should be replaced by respective theme switching components.
export const Layout = AudiLayout;
export const FormGroup = AudiFormGroup;
export const Divider = AudiDivider;
export const LayoutItem = AudiLayoutItem;
export const Search = AudiSearch;
export const Loader = AudiLoader;
export const Toggle = AudiToggle;
export const Accordion = AudiAccordion;
export const AccordionSection = AudiAccordionSection;

// These should be considered deprecated. A retheming is probably not worth it.
export const NativeCheckbox = Checkbox;
export const NativeSelect = Select;
export const NativeRadioButton = RadioButton;
export const NativeTextField = TextField;
export const NativeTextArea = TextArea;

export { LayoutItemProps };
