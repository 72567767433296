import React from 'react';
import { useIsTheme } from '../../theming';
import { NotImplemented } from '../../utils/NotImplemented';
import { UserIconDark } from './UserIconDark';
import { UserIconLight } from './UserIconLight';
import { UserIconAudi } from './UserIconAudi';

interface P {
  support?: boolean;
}

const UserIcon = (props: P) => {
  const theme = useIsTheme();
  if (theme.neutralDark) {
    return <UserIconDark support={props.support} />;
  } else if (theme.neutralLight) {
    return <UserIconLight support={props.support} />;
  } else if (theme.audi) {
    return <UserIconAudi />;
  } else return <NotImplemented />;
};
/** @deprecated Use UserIconAudi instead */
export const UserIconWrapper = UserIcon;
export { UserIcon };
