import React from 'react';
import { SvgIcon } from '@material-ui/core';

const MenuIconNeutral = () => (
  <SvgIcon fontSize="inherit" viewBox="0 0 30 30">
    <path id="Pfad_303" data-name="Pfad 303" d="M0,0H30V30H0Z" fill="none" />
    <path
      id="Pfad_304"
      data-name="Pfad 304"
      d="M3,16H25.5V13.5H3Z"
      transform="translate(0.75 0.25)"
    />
    <path
      id="Pfad_305"
      data-name="Pfad 305"
      d="M3,6V8.5H25.5V6Z"
      transform="translate(0.75 1.5)"
    />
    <path
      id="Pfad_306"
      data-name="Pfad 306"
      d="M3,23.5H25.5V21H3Z"
      transform="translate(0.75 -1)"
    />
  </SvgIcon>
);

export { MenuIconNeutral };
