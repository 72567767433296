import React from 'react';
import { makeStyles, SvgIcon } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  svgWrapper: {
    fontSize: '36px',
    color: '#7C7C7C',
    cursor: 'pointer',
    '&:hover': {
      color: '#ffffff',
    },
  },
  svgSupportWrapper: {
    fontSize: '30px',
    color: '#ffffff',
    cursor: 'pointer',
  },
}));
const UserIconDark = (props: { support?: boolean }) => {
  const cls = useStyles();
  return (
    <SvgIcon className={props.support ? cls.svgSupportWrapper : cls.svgWrapper}>
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z" />
    </SvgIcon>
  );
};

export { UserIconDark };
